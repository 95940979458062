import moment from "moment";
import { getCookie } from "./session";
import { toast } from "react-toastify";

export const getAccountName = () => {
  const name = getCookie("accountName");
  return name;
};
export const getAccountId = () => {
  const id = getCookie("accountId");
  return id;
};

export const getOwnerId = () => {
  const id = getCookie("ownerId");
  return id;
};

export const getCustomerType = () => {
  const id = getCookie("customerType");
  return id;
};

export const getNameFirstLetter = (name = "", letercount = 2) => {
  let latter = "";
  let i = 0;
  while (i < letercount) {
    latter += name?.[i]?.toUpperCase() || "";
    i++;
  }
  return latter;
};

export const formateDate = (date) => {
  return date ? moment(date).format("DD-MM-YYYY") : "";
};

export const formateNumber = (n) => {
  return n < 1 || n > 9 ? "" + n : "0" + n;
};

export const getFileName = (name) => {
  return name?.split(".")?.[0];
};

export const convertToBlob = (blob) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      var base64data = reader.result;
      resolve(base64data?.split(",")?.[1]);
    };
  });
};

const currLocal = {
  USD: "en-US",
  EUR: "en-EU",
  GBP: "en-GB",
  JPY: "ja-JP",
  CHF: "de-CH",
  CAD: "en-CA",
  AUD: "en-AU",
  CNY: "zh-CN",
  INR: "en-IN",
  BRL: "pt-BR",
  RUB: "ru-RU",
  ZAR: "en-ZA",
  MXN: "es-MX",
  SGD: "en-SG",
  NZD: "en-NZ",
  HKD: "en-HK",
  NOK: "nb-NO",
  SEK: "sv-SE",
  DKK: "da-DK",
  TRY: "tr-TR",
  KRW: "ko-KR",
  MYR: "en-MY",
  IDR: "id-ID",
  THB: "th-TH",
  SAR: "ar-SA",
  AED: "ar-AE",
  ILS: "he-IL",
  SEK: "sv-SE",
  PLN: "pl-PL",
  HUF: "hu-HU",
};

export const numberFormat = (value, cType) => {
  const locale = currLocal?.[cType];
  const val = new Intl.NumberFormat(locale || "en-IN", {
    currency: cType || "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  return val;
};

export const getFormatedData = (type, data, cType) => {
  switch (type) {
    case "date":
      return formateDate(data);
    case "percentage":
      return data ? data + "%" : "0%";
    case "currency":
      return data
        ? `${cType || "INR"} ` + numberFormat(data, cType)
        : `${cType || "INR"} 0`;

    case "number":
      return data ? formateNumber(data) : "0";
    default:
      return data || "";
  }
};
export const buildColumns = (columns) => {
  const cType = getCookie("currencyIsoCode");
  return columns?.map((column) => {
    return {
      Header: column?.fieldLabel ? column?.fieldLabel : "",
      accessor: column?.fieldName ? column?.fieldName : "",
      Cell: (row) => {
        return getFormatedData(column?.fieldType, row?.value, cType);
      },
      ...column,
    };
  });
};

// date,  percentage,  string, number,  currency

export const calculateGST = (amount) => {
  const gst = amount / 100;

  return gst;
};

export const getFileType = (file) => {
  const fileExtension = getFileExtension(file);
  if (fileExtension?.toLocaleLowerCase() === "pdf") {
    return 1;
  } else {
    return 2;
  }
};

export const getFileExtension = (file = "") => {
  const splitPath = file?.split(".");
  return splitPath?.[splitPath?.length - 1];
};

export const downloadFile = (url, fileName) => {
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      this.downloadFileFormObj(blob, fileName);
      // or you know, something with better UX...
    })
    .catch((e) => {});
};

export function exportToCsv(rows, filename, keyMapper) {
  if (!rows.length) return;
  filename = filename ? filename.replace(/ /g, "_") : "excel_list";
  keyMapper.mapper = keyMapper.mapper || {};
  var processRow = function (row) {
    var finalVal = "";
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  var csvFile = "";
  csvFile += processRow(keyMapper.header);
  for (var i = 0; i < rows.length; i++) {
    let row = [];
    for (let k = 0; k < keyMapper.keys.length; k++) {
      if (Array.isArray(keyMapper.keys[k])) {
        if (keyMapper.keys[k].length) {
          let fvalue = keyMapper.keys[k].map((ik) => {
            return rows[i][ik] ? rows[i][ik] : "";
          });
          row[k] = fvalue.join("-");
        } else {
          row[k] = "--";
        }
      } else {
        row[k] =
          rows[i][keyMapper.keys[k]] || rows[i][keyMapper.keys[k]] === 0
            ? keyMapper.mapper[keyMapper.keys[k]]
              ? keyMapper.mapper[keyMapper.keys[k]][rows[i][keyMapper.keys[k]]]
              : rows[i][keyMapper.keys[k]]
            : "--";
      }
    }
    csvFile += processRow(row);
  }

  var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename + ".csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success("File downloaded successfully");
    }
  }
}
