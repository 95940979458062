import React from "react";

function FmiCases({ color = "black" }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.0629 23.9607C22.7868 23.9607 22.5629 24.1873 22.5629 24.4674V30.1553C22.5629 30.6135 22.1947 30.9866 21.7424 30.9866H1.82053C1.36814 30.9866 0.999985 30.6135 0.999985 30.1553V1.84469C0.999985 1.38649 1.36815 1.01339 1.82053 1.01339H16.9153V5.18571C16.9153 5.76762 17.3823 6.24067 17.9563 6.24067H22.5629V9.68165C22.5629 9.96171 22.7868 10.1883 23.0629 10.1883C23.339 10.1883 23.5629 9.96171 23.5629 9.68165V5.73447C23.5629 5.62917 23.5061 5.46435 23.4048 5.36706C23.3542 5.31845 17.8034 0.176062 17.7532 0.132612C17.753 0.132491 17.7527 0.132364 17.7525 0.132243L17.7524 0.132116C17.6633 0.0497626 17.5164 0 17.416 0H1.82053C0.816638 0 0 0.827337 0 1.84469V30.1553C0 31.1727 0.816638 32 1.82053 32H21.7424C22.7463 32 23.5629 31.1727 23.5629 30.1553V24.4674C23.5629 24.1873 23.339 23.9607 23.0629 23.9607ZM17.9563 5.22728C17.9336 5.22728 17.9153 5.20847 17.9153 5.18571V1.6553C18.3852 2.0904 21.4741 4.95022 21.7734 5.22728H17.9563Z"
        fill={color}
      />
      <path
        d="M2.98339 4.74386H11.7484C12.0245 4.74386 12.2484 4.51723 12.2484 4.23716C12.2484 3.9571 12.0245 3.73047 11.7484 3.73047H2.98339C2.70727 3.73047 2.4834 3.9571 2.4834 4.23716C2.4834 4.51723 2.70727 4.74386 2.98339 4.74386Z"
        fill={color}
      />
      <path
        d="M20.7385 8.94224C20.7385 8.66218 20.5146 8.43555 20.2385 8.43555H2.98339C2.70727 8.43555 2.4834 8.66218 2.4834 8.94224C2.4834 9.22231 2.70727 9.44894 2.98339 9.44894H20.2385C20.5146 9.44894 20.7385 9.22231 20.7385 8.94224Z"
        fill={color}
      />
      <path
        d="M11.7484 13.1406H2.98339C2.70727 13.1406 2.4834 13.3673 2.4834 13.6473C2.4834 13.9274 2.70727 14.154 2.98339 14.154H11.7484C12.0245 14.154 12.2484 13.9274 12.2484 13.6473C12.2484 13.3673 12.0245 13.1406 11.7484 13.1406Z"
        fill={color}
      />
      <path
        d="M2.98339 18.8586H11.7484C12.0245 18.8586 12.2484 18.632 12.2484 18.3519C12.2484 18.0718 12.0245 17.8452 11.7484 17.8452H2.98339C2.70727 17.8452 2.4834 18.0718 2.4834 18.3519C2.4834 18.632 2.70727 18.8586 2.98339 18.8586Z"
        fill={color}
      />
      <path
        d="M13.7052 22.5498H2.98339C2.70727 22.5498 2.4834 22.7764 2.4834 23.0565C2.4834 23.3366 2.70727 23.5632 2.98339 23.5632H13.7052C13.9813 23.5632 14.2052 23.3366 14.2052 23.0565C14.2052 22.7764 13.9813 22.5498 13.7052 22.5498Z"
        fill={color}
      />
      <path
        d="M2.4834 27.7616C2.4834 28.0416 2.70727 28.2683 2.98339 28.2683H20.2385C20.5146 28.2683 20.7385 28.0416 20.7385 27.7616C20.7385 27.4815 20.5146 27.2549 20.2385 27.2549H2.98339C2.70727 27.2549 2.4834 27.4815 2.4834 27.7616Z"
        fill={color}
      />
      <path
        d="M18.3341 12.2272C16.1009 12.7092 14.5974 14.6625 14.487 16.7919C14.4726 17.0715 14.6843 17.31 14.9602 17.3248C14.969 17.3253 14.978 17.3253 14.9868 17.3253C15.2509 17.3253 15.4716 17.1155 15.4855 16.8453C15.5741 15.1339 16.7897 13.5965 18.5421 13.2188C18.8122 13.1604 18.9848 12.8912 18.9271 12.6176C18.8695 12.344 18.6049 12.1683 18.3341 12.2272Z"
        fill={color}
      />
      <path
        d="M31.1566 23.4763L30.1832 22.691C29.9669 22.5173 29.6525 22.5529 29.4806 22.7717C29.3085 22.9909 29.3444 23.3095 29.5602 23.4837L30.5336 24.269C31.066 24.6981 31.155 25.4843 30.7301 26.0246C30.3021 26.5693 29.528 26.6508 28.9977 26.224L25.5974 23.4802L27.1332 21.5252L27.8852 22.1319C28.101 22.3065 28.4159 22.2704 28.5878 22.0512C28.7599 21.832 28.7243 21.5133 28.5082 21.3392L27.3652 20.4168C27.1463 20.2398 26.8322 20.2818 26.6626 20.4975L26.6027 20.5737L25.6831 19.8316C26.9194 16.9387 26.1111 13.5981 23.6519 11.6194C22.0228 10.3142 19.9223 9.83158 17.9225 10.2715C12.3045 11.4827 10.5458 18.8545 15.0966 22.5188C17.6202 24.5541 21.2418 24.5139 23.7097 22.4801L24.5635 23.1691L24.5036 23.2452C24.3315 23.4644 24.3672 23.7831 24.5832 23.9572L28.3747 27.0167C29.3356 27.7917 30.7408 27.6388 31.5123 26.656C32.2812 25.6782 32.1221 24.2555 31.1566 23.4763ZM15.7189 21.7256C11.8191 18.5853 13.3529 12.2931 18.1329 11.2621C19.8934 10.8741 21.6858 11.3327 23.0369 12.4185C25.484 14.3873 25.9825 17.9187 24.1746 20.5023L23.9935 20.7517C21.366 23.6336 17.9787 23.5482 15.7189 21.7256ZM24.9892 21.0903C24.9895 21.0899 24.9898 21.0894 24.9901 21.089L24.9909 21.0878C24.992 21.0863 24.9929 21.0847 24.9939 21.0832C25.0703 20.9736 25.1438 20.8622 25.2138 20.7483L25.9785 21.3654L25.1853 22.3753L24.4436 21.7766C24.7111 21.4804 24.8701 21.2611 24.9892 21.0903Z"
        fill={color}
      />
    </svg>
  );
}

export default FmiCases;
