import React from "react";
import "./header.scss";
import Logo from "../../assets/logo.svg";
import useAuth from "../../controller/auth/auth-controller";
import { getAccountName, getNameFirstLetter } from "../../utils/helper";

const Header = () => {
  const authController = useAuth();
  const name = getAccountName();
  const { logout } = authController;

  return (
    <>
      <div
        className={`header p-2  flex items-center justify-between fixed top-0	w-full`}
      >
        <div className={`flex w-full items-center justify-between`}>
          <img src={Logo} className="header-logo" alt="logo-icon" />
          <div className="flex items-center">
            <div className="rounded-full mr-2 bg-primary text-white w-10 h-10  flex justify-center items-center">
              {getNameFirstLetter(name || "D")}
            </div>
            <div>
              <p
                className="ml-0 mr-2 mb-0 font-medium text-sm logout cursor-pointer"
                onClick={() => logout()}
              >
                Logout
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
