import { useState } from "react";
import axiosInstance from "../../utils/AxiosAPI";
import { getCookie } from "../../utils/session";
import { exportToCsv } from "../../utils/helper";
import moment from "moment";

const useOrder = () => {
  const [orderDetail, setOrderDetail] = useState({});
  const [orderProdDetail, setOrderProdDetail] = useState({});
  const [createdOrderData, setCreatedOrderData] = useState({});
  const [orderLineDetail, setOrderLineDetail] = useState({});
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getOrderDetails = async (id) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);

    try {
      const response = await axiosInstance.post(`GetOrderDetail/`, {
        OrderId: id,
      });
      if (response?.data?.data) {
        setSuccMsg(response?.data?.message);
        setOrderDetail(response?.data?.data);
      } else {
        setErrMsg(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  const createOrder = async (data, isCreate = false) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);

    try {
      const response = await axiosInstance.post(`CreateOrder/`, {
        isCreateOrder: isCreate,
        ...data,
      });
      if (response?.data?.data) {
        if (isCreate) setSuccMsg(response?.data?.message);
        else setCreatedOrderData(response?.data?.data);
      } else {
        setErrMsg(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  const getOrderProductDetails = async (data, cb) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    const accountId = getCookie("accountId");

    try {
      const response = await axiosInstance.post(`GetOrderProductDetails/`, {
        accountId,
        ...data,
      });
      if (response?.data?.data) {
        // setSuccMsg(response?.data?.message);

        setOrderProdDetail(response?.data?.data);
        typeof cb !== "undefined" && cb(response?.data?.data);
      } else {
        setErrMsg(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  const getOrderLineDetails = async (id) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);

    try {
      const response = await axiosInstance.post(`GetOrderLineDetail/`, {
        orderLineId: id,
      });
      if (response?.data?.data) {
        setSuccMsg(response?.data?.message);
        setOrderLineDetail(response?.data?.data);
      } else {
        setErrMsg(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  const downloadPendingOrders = async () => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    const accountId = getCookie("accountId");

    try {
      const response = await axiosInstance.post(`PendingOrder/`, {
        accountId,
      });
      if (response?.data?.status) {
        const keymapper = {
          header: response?.data?.data?.fields?.map((dt) => dt?.fieldLabel),
          keys: response?.data?.data?.fields?.map((dt) => dt?.fieldName),
        };
        exportToCsv(
          response?.data?.data?.records,
          "Pending_orders_" + moment().format("DD-MM-YYYY"),
          keymapper
        );
      } else {
        setErrMsg(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  return {
    orderDetail,
    orderProdDetail,
    createdOrderData,
    orderLineDetail,
    succMsg,
    errMsg,
    isLoading,
    getOrderDetails,
    createOrder,
    getOrderProductDetails,
    getOrderLineDetails,
    downloadPendingOrders,
  };
};

export default useOrder;
