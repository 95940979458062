import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
const FormSelect = (props) => {
  const {
    label = "",
    error = "",
    errorMessage,
    preFix,
    postFix,
    errorClass = "",
    extraContent,
    defaultOptions,
    wrapperClass = "",
    containerclass = "",
    fullwidth = false,
    options,
    name,
    onChange,
    value,
    className,
    isCreatable = false,
    isClearable = true,
    isAsync = false,
    isSearchable = true,
    required = false,
    loadOptions,
  } = props;

  const ref = useRef();
  const [selectValue, setValues] = useState({});

  useEffect(() => {
    const filteredObj =
      options?.length > 0
        ? options?.filter((op) => {
            return op?.value === value?.value;
          })
        : [];

    setValues(filteredObj?.[0] || value);
  }, [options, value]);

  const onInputChange = (value, option) => {
    if (option?.action === "input-change") {
      onChange({
        label: value,
        value,
      });
    }
  };

  const IndicatorSeparator = ({ innerProps }) => {
    return <span style={{ display: "none" }} {...innerProps} />;
  };

  return (
    <div className={`${wrapperClass ? wrapperClass : ""}`}>
      {label && (
        <div className="px-0">
          <label className="mb-2">{label}</label>
          {required ? <span className="asterisk-label ml-1">*</span> : null}
        </div>
      )}
      <div
        className={`d-flex align-items-center position-relative ${
          containerclass ? containerclass : ""
        }`}
      >
        {preFix && preFix()}
        {isAsync ? (
          <AsyncSelect
            menuPlacement="top"
            isClearable={isClearable}
            isSearchable={true}
            loadOptions={loadOptions}
            name={name}
            onChange={onChange}
            defaultOptions={defaultOptions}
            onInputChange={isCreatable ? onInputChange : () => {}}
            value={selectValue}
            className={`form-control formSelect py-1 px-0.5 ${className} ${
              error ? "form-control-error" : ""
            } ${fullwidth ? "w-full" : ""}`}
            components={{ IndicatorSeparator }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: "transparent",
                boxShadow: "none",
                "&:hover": {
                  borderColor: "transparent",
                },
              }),
              menu: (baseStyles, state) => ({
                ...baseStyles,
                zIndex: 2,
              }),
              menuList: (baseStyles, state) => ({
                ...baseStyles,
                maxHeight: 200,
              }),
            }}
            theme={(theme) => {
              return {
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#FFF4F4",
                  neutral0: "inherit",
                  primary25: "#f4f3f4",
                },
              };
            }}
          />
        ) : (
          <Select
            isClearable={isClearable}
            isSearchable={isSearchable}
            options={options || []}
            name={name}
            onChange={onChange}
            onInputChange={isCreatable ? onInputChange : () => {}}
            value={selectValue}
            className={`form-control formSelect py-1 px-0.5 ${className} ${
              error ? "form-control-error" : ""
            } ${fullwidth ? "w-full" : ""}`}
            components={{ IndicatorSeparator }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: "transparent",
                boxShadow: "none",
                "&:hover": {
                  borderColor: "transparent",
                },
              }),
              menu: (baseStyles, state) => ({
                ...baseStyles,
                zIndex: 2,
              }),
              menuList: (baseStyles, state) => ({
                ...baseStyles,
                maxHeight: 200,
              }),
            }}
            theme={(theme) => {
              return {
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#FFF4F4",
                  neutral0: "inherit",
                  primary25: "#f4f3f4",
                },
              };
            }}
          />
        )}
        {postFix && postFix()}
        {extraContent && extraContent()}
      </div>
      {error ? (
        <div className="input-error-message mt-2.5">
          <span className={` ${errorClass}`}>{errorMessage}</span>
        </div>
      ) : null}
    </div>
  );
};

export default FormSelect;
