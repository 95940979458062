import React from "react";
import "./table.scss";
import Pagination from "./Pagination";

function Table(props) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // page,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,
    // pageIndex,
    // pageSize,
    rowClick,
    fullHeigth,
    orderCol,
    setOrderCol,
    orderType,
    setOrderType,
  } = props;

  const handleOrder = (column) => {
    let orType = "";

    if (orderCol === column.fieldName) {
      if (orderType === "") {
        orType = "ASC";
      } else if (orderType === "ASC") {
        orType = "DESC";
      } else if (orderType === "DESC") {
        orType = "";
        setOrderCol("");
      }
    } else {
      setOrderCol(column.fieldName);
      orType = "ASC";
    }

    setOrderType(orType);
    props.gotoPage(
      props?.pageIndex,
      orType === "" ? "" : column?.fieldName,
      orType === "" ? "" : column?.fieldAPIName,
      orType
    );
  };

  return (
    <div className="table-container">
      <div
        className="rounded-lg overflow-auto"
        style={{
          height: fullHeigth
            ? props.pageCount > 0
              ? "calc(100vh - 230px)"
              : "calc(100vh - 205px)"
            : "auto",
          background: "#ffffff",
        }}
      >
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    {...column.getHeaderProps(
                      column?.id !== "action" && column.getSortByToggleProps()
                    )}
                    className={`p-3 font-bold ${
                      i === 0 ? "rounded-tl-lg" : ""
                    } ${
                      i === headerGroup.headers.length - 1
                        ? "rounded-tr-lg"
                        : ""
                    }`}
                    onClick={() => handleOrder(column)}
                  >
                    {column.render("Header")}
                    <span className="absolute right-3">
                      {orderCol === column.fieldName &&
                      (orderType === "ASC" || orderType === "DESC") ? (
                        orderType === "DESC" ? (
                          <div className="accIconClose inline-block rotate-45 ml-2" />
                        ) : (
                          <div className="accIconOpen inline-block mr-3 ml-2" />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={i}
                  onClick={() => rowClick(row)}
                  className="cursor-pointer"
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="p-3">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {props.pageCount > 0 ? (
        <div className="pagination flex flex-row justify-end mt-5">
          <Pagination {...props} />
        </div>
      ) : null}
      {/* <div className="pagination flex flex-row justify-end mt-5">
        {/* <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div> */}
    </div>
  );
}

export default Table;
