import React, { useState, useEffect, useRef, useCallback } from "react";
import TableContainer from "../../components/table/TableContainer";
import AccImg from "../../assets/accounts/account.svg";
import "./fmi-cases.scss";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/table/pageHeader/PageHeader";
import { useDialogContext } from "../../components/dialogs/Dialog";
import useList from "../../controller/list/list-controller";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { debounce } from "debounce";
import { PER_PAGE } from "../../utils/constants";
import { buildColumns } from "../../utils/helper";

const FmiCases = () => {
  const [search, setSearch] = useState();
  const [sortOptions, setSortOptions] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [sort, setSort] = useState("");
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [totalRec, setTotalRec] = useState(0);
  const [flag, setFlag] = useState(true);
  const [orderCol, setOrderCol] = useState("");
  const [orderType, setOrderType] = useState("");
  const { dispatch } = useDialogContext();
  const navigate = useNavigate();
  const prevStateRef = useRef();
  const {
    getListViews,
    getListViewRecords,
    getConstants,
    listData,
    tableData,
    allConst,
    isLoading,
    errMsg,
  } = useList();

  useEffect(() => {
    setFlag(false);
    getListViews({
      objectName: "Case",
    });
    getConstants({ objectName: "case" });
  }, []);

  useEffect(() => {
    setIsLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (errMsg) {
      toast.error(errMsg);
    }
  }, [errMsg]);

  useEffect(() => {
    if (listData?.listViews?.length > 0) {
      const otpData = listData?.listViews?.map((dt) => ({
        label: dt.listViewName,
        value: dt.listViewId,
      }));
      const actFil = listData?.listViews?.find((dt) => dt?.fields?.length > 0);
      setSort(actFil?.listViewId);
      setSortOptions([...otpData]);
    }
  }, [listData]);

  useEffect(() => {
    const header = buildColumns(tableData?.fields);

    setColumns(header);
    setData(tableData?.records);
    (tableData?.totalRecordCount || tableData?.totalRecordCount === 0) &&
      setTotalRec(
        tableData?.totalRecordCount
          ? parseInt(tableData?.totalRecordCount) / PER_PAGE
          : 1
      );
    // (tableData?.pageNo || tableData?.pageNo === 0) &&
    //   setCurrPage(tableData?.pageNo - 1);
  }, [tableData]);

  useEffect(() => {
    prevStateRef.current !== sort && setSearch("");
    if (sort) {
      if (orderCol || orderType) {
        setOrderCol("");
        setOrderType("");
      }
      if (!flag) getList(search, sort);
    }
    prevStateRef.current = sort;
  }, [sort]);

  const getList = (searchData = search, sortData = sort) => {
    getListViewRecords({
      objectName: "Case",
      listViewId: sortData,
      pageNo: 1,
      orderByField: "",
      orderByAPIField: "",
      orderDirection: "",
      searchKey: searchData,
    });
  };

  const getSearchDebounce = useCallback(
    debounce((searchData, sortData) => {
      getListViewRecords({
        objectName: "Case",
        listViewId: sortData,
        pageNo: 1,
        orderByField: "",
        orderByAPIField: "",
        orderDirection: "",
        searchKey: searchData,
      });
    }, 500),
    []
  );

  const handleGetListViews = () => {
    getListViews({
      objectName: "Case",
    });
    getList("", sort);
  };

  const handleGetProducts = (page = 0, field = "", apiField = "", dir = "") => {
    let pageNo = page + 1;
    setCurrPage(page);
    getListViewRecords({
      objectName: "Case",
      listViewId: sort,
      pageNo: pageNo,
      orderByField: field,
      orderByAPIField: apiField,
      orderDirection: dir,
      searchKey: search,
    });
  };

  const handleSearch = (val) => {
    if (orderCol || orderType) {
      setOrderCol("");
      setOrderType("");
    }
    setSearch(val);
    getSearchDebounce(val, sort);
  };

  const handleRowClick = (row) => {
    navigate(`/fmi-cases/${row?.original?.caseId}`);
  };

  return (
    <div className="fmi-container h-full flex flex-col">
      {isLoader && !data ? (
        <Loader />
      ) : data?.length > 0 || columns?.length > 0 ? (
        <>
          <PageHeader
            img={AccImg}
            text={"FMI Cases"}
            isFilter={true}
            options={sortOptions}
            sort={sort}
            setSort={setSort}
            isSearch={true}
            search={search}
            setSearch={handleSearch}
            isCreate={true}
            onCreateClick={() =>
              dispatch({
                type: "openDialog",
                payload: {
                  data: {
                    getListViews: handleGetListViews,
                    allConst: allConst,
                    footer: false,
                    cancelBtn: true,
                    title: "New FMI Case",
                    className: "pb-0",
                  },
                  path: `fmi-cases/Step1`,
                },
              })
            }
          />
          <TableContainer
            data={data}
            columns={columns?.filter((col) => {
              return col?.fieldLabel;
            })}
            rowClick={handleRowClick}
            gotoPage={handleGetProducts}
            pageIndex={currPage}
            isLoading={isLoader}
            pageCount={totalRec}
            orderCol={orderCol}
            setOrderCol={setOrderCol}
            orderType={orderType}
            setOrderType={setOrderType}
          />
        </>
      ) : null}
    </div>
  );
};

export default FmiCases;
