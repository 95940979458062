import React from "react";
import "./table.scss";
import {
  usePagination,
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from "react-table";
import Table from "./Table";
import Placeholder from "../../svgs/placeholder";
import { FadeLoader } from "react-spinners";

function TableContainer(props) {
  const {
    columns,
    data,
    rowClick,
    fullHeigth = true,
    gotoPage,
    pageIndex,
    pageCount,
    isLoading,
    orderCol,
    setOrderCol,
    orderType,
    setOrderType,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    // pageCount,
    // gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {
      // pageIndex,
      pageSize,
    },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className="relative h-full">
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        page={page}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        rowClick={rowClick}
        fullHeigth={fullHeigth}
        orderCol={orderCol}
        setOrderCol={setOrderCol}
        orderType={orderType}
        setOrderType={setOrderType}
      />
      {isLoading ? (
        <div className=" table-spinner  text-center">
          <FadeLoader color="#e11b22" />
        </div>
      ) : rows?.length === 0 ? (
        <div className="absolute-center text-center">
          <Placeholder></Placeholder>
          <div className="font-semibold mt-2 text-sm">No Record Found!</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default TableContainer;
