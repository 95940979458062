import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  createContext,
} from "react";
import Modal from "react-modal";
import CloseIcon from "../../assets/close.svg";
import Search from "../table/Search";
import "./dialog.scss";

const DialogContext = createContext({});

const initialState = {
  isOpen: false,
  data: {},
  path: "",
  body: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "openDialog":
      return {
        ...state,
        isOpen: true,
        data: action?.payload?.data,
        path: action?.payload?.path,
        body: action?.payload?.body,
      };
    case "closeDialog":
      return {
        ...state,
        isOpen: false,
        data: {},
        path: "",
        body: null,
      };
    default:
      return state;
  }
};

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 2,
  },
  content: {
    top: "50%",
    left: "50%",
    overflow: "hidden",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: 0,
    transform: "translate(-50%, -50%)",
    height: "90vh",
    maxWidth: "80vw",
  },
};

export function DialogContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [modalState, setModel] = useState({ isLoaded: false });
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);
  const [modalData, setModalData] = useState({});
  const [search, setSearch] = useState("");

  const { data = {}, isOpen } = state;

  const {
    title,
    footer,
    cancelBtn,
    onSubmit,
    className,
    closeButton = true,
    buttonText = {},
    submitArg,
    onCancel,
    disabledBackdrop = false,
    confModal = false,
    isSearch = false,
    onSearchChange = () => {},
    modalType = "",
  } = data;

  let handleCloseBUtton = closeButton;
  const { module: Component, isLoaded, body = "" } = modalState;

  let extraObj = {};
  let modelDynamic = {};
  if (!disabledBackdrop) {
    extraObj["closeButton"] = closeButton;
  } else {
    modelDynamic["onBackdropClick"] = "false";
    modelDynamic["keyboard"] = true;
    handleCloseBUtton = false;
  }

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  useEffect(() => {
    if (state.path) {
      import(`./${state.path}`).then((module) =>
        setModel({
          module: module.default,
          isLoaded: state.isOpen ? true : false,
          body: "",
        })
      );
    } else {
      setIsSubmitDisable(false);
      setModel({
        body: state.body,
        module: "",
        isLoaded: state.isOpen ? true : false,
      });
    }
  }, [state]);

  function afterOpenModal() {}
  const onClose = () => {
    dispatch({
      type: "closeDialog",
    });
    setModalData({});
  };

  return (
    <DialogContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {isLoaded && (
        <Modal
          isOpen={isOpen}
          toggle={onClose}
          centered={true}
          className={confModal ? "" : ""}
          onAfterOpen={afterOpenModal}
          onRequestClose={onClose}
          style={{
            ...customStyles,
            content: {
              ...customStyles.content,
              height: modalType === "changePassword" ? "fit-content" : "90vh",
            },
          }}
        >
          {state && (
            <div className="h-full flex flex-col overflow-hidden">
              {title && (
                <div className="modal-header font-semibold text-lg sticky top-0">
                  <div
                    className={`relative p-5 ${
                      isSearch
                        ? "flex flex-row justify-between items-center"
                        : "text-center"
                    }`}
                  >
                    {title}
                    <div
                      className={`flex flex-row items-center ${
                        !isSearch ? "absolute right-5 top-6" : ""
                      }`}
                    >
                      {isSearch ? (
                        <div className="mr-3">
                          <Search
                            search={search}
                            setSearch={setSearch}
                            onChange={onSearchChange}
                          />
                        </div>
                      ) : null}
                      {handleCloseBUtton && (
                        <button
                          onClick={(e) => {
                            onClose();
                            onCancel && onCancel(e, onClose);
                          }}
                        >
                          <img src={CloseIcon} alt="close" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`${
                  footer ? "overflow-y-auto" : "overflow-y-auto"
                } modal-body h-full px-8 py-5 ${className}`}
                id="modal-body"
              >
                {isLoaded && Component && (
                  <Component
                    {...data}
                    key={isOpen}
                    closeModal={onClose}
                    setIsSubmitDisable={setIsSubmitDisable}
                    modalData={modalData}
                    setModalData={setModalData}
                    search={search}
                    setSearch={setSearch}
                  />
                )}
                {isLoaded && body && body}
              </div>
              {footer && (
                <div
                  className="modal-footer sticky bottom-0 p-5 flex flex-row justify-center"
                  id="modal-footer"
                >
                  {cancelBtn && (
                    <button
                      className="btn btn-primary-outlined mx-1.5 capitalize"
                      onClick={(e) => {
                        onClose();
                        onCancel && onCancel(e, onClose);
                      }}
                    >
                      {buttonText.cancel || "Cancel"}
                    </button>
                  )}
                  {onSubmit && (
                    <button
                      autoFocus
                      onClick={(e) => {
                        onSubmit(e, onClose, modalData, submitArg);
                      }}
                      className="btn btn-primary mx-1.5 capitalize"
                      disabled={isSubmitDisable}
                    >
                      {buttonText.submit || "Ok"}
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </Modal>
      )}
      {props.children}
    </DialogContext.Provider>
  );
}

export const useDialogContext = () => useContext(DialogContext);
