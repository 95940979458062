import React, { useState, useEffect } from "react";
import "./order-details.scss";
import OrderImg from "../../assets/orders/order.svg";
import InfoImg from "../../assets/accounts/info.svg";
import FulfillImg from "../../assets/orders/fulfillment.svg";
import FinanImg from "../../assets/orders/financial.svg";
import AddressImg from "../../assets/orders/address.svg";
import AdditImg from "../../assets/orders/additional.svg";
import Order2Img from "../../assets/orders/order_2.svg";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/table/pageHeader/PageHeader";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import useOrder from "../../controller/order/order-controller";
import moment from "moment";
import { formateDate, getFormatedData, numberFormat } from "../../utils/helper";

const OrderDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { getOrderDetails, isLoading, errMsg, orderDetail } = useOrder();
  const [search, setSearch] = useState();
  const [openAcc, setOpenAcc] = useState(["0", "1", "2", "3", "4", "5"]);
  const [isLoader, setIsLoader] = useState(false);
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    getOrderDetails(params?.oid);
  }, []);

  useEffect(() => {
    setOrderData(orderDetail);
  }, [orderDetail]);

  useEffect(() => {
    setIsLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (errMsg) {
      toast.error(errMsg);
    }
  }, [errMsg]);

  return (
    <div className="order-details-container h-full flex flex-col">
      {isLoader ? (
        <Loader />
      ) : (
        <>
          <PageHeader
            img={OrderImg}
            text="Order Details"
            subTitle={orderData?.orderName}
            isCreate={false}
            isDelete={false}
            onCreateClick={() => navigate(`/orders/create`)}
            search={search}
            setSearch={setSearch}
            isBackBtn={true}
            onBackClick={() => navigate("/orders")}
          />
          <div className="accordianWrapper">
            <Accordion
              allowMultipleExpanded={true}
              allowZeroExpanded={true}
              preExpanded={["0", "1", "2", "3", "4", "5"]}
              onChange={(item) => setOpenAcc(item)}
            >
              <AccordionItem uuid={"0"} className="accItem mb-3">
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                      openAcc.includes("0") ? "rounded-t-lg" : "rounded-lg"
                    }`}
                  >
                    <div className="flex flex-row font-semibold text-sm accTitle">
                      <img src={InfoImg} alt="infoImg" className="mr-2" />
                      Basic Information
                    </div>
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded ? (
                          <div className="accIconOpen inline-block mr-3" />
                        ) : (
                          <div className="accIconClose inline-block mr-3 rotate-45" />
                        )
                      }
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accPanel grid rounded-b-lg py-6 px-4">
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm">
                          Order No.
                        </div>
                        <div className="fieldDetails font-medium text-sm ">
                          {orderData?.orderName}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm">
                          Account Name
                        </div>
                        <div className="fieldDetails font-medium text-sm  userData">
                          {orderData?.accountName}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm">
                          Buyer PO Date
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {formateDate(orderData?.buyerPODate)}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm">
                          Buyer PO Number
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {orderData?.buyerPONumber}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm">
                          Order Creation Date
                        </div>
                        <div className="fieldDetails font-medium text-sm ">
                          {moment(orderData?.createdDate).format(
                            "DD-MM-YYYY hh:mm a"
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm">
                          Dealer Portal Order
                        </div>
                        <div className="fieldDetails font-medium text-sm ">
                          {orderData?.isPortalOrder}
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={"1"} className="accItem mb-3">
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                      openAcc.includes("1") ? "rounded-t-lg" : "rounded-lg"
                    }`}
                  >
                    <div className="flex flex-row font-semibold text-sm accTitle">
                      <img src={FulfillImg} alt="AddressImg" className="mr-2" />
                      Fulfillment Information
                    </div>
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded ? (
                          <div className="accIconOpen inline-block mr-3" />
                        ) : (
                          <div className="accIconClose inline-block mr-3 rotate-45" />
                        )
                      }
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accPanel grid rounded-b-lg py-6 px-4">
                    <div className="grid grid-cols-2">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm">
                          Order Status
                        </div>
                        <div className="fieldDetails font-medium text-sm ">
                          {orderData?.status}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm">
                          Order Age
                        </div>
                        <div className="fieldDetails font-medium text-sm ">
                          {orderData?.age}
                        </div>
                      </div>
                    </div>
                    {orderData?.orderLineList?.length > 0 ? (
                      <div className="table-container card  mt-10 ">
                        <div className="text-sm flex  items-center text-re accTitle font-semibold mb-3">
                          <img
                            src={Order2Img}
                            alt="SystemImg"
                            className="mr-2"
                          />
                          <div> Order Items</div>
                        </div>
                        <div
                          className="overflow-auto mt-4 order-list-table  shadow-sm rounded-md"
                          style={{
                            height: "auto",
                            background: "#ffffff",
                          }}
                        >
                          <table className="w-full">
                            <thead>
                              <tr role="row">
                                {orderData?.fields?.map((field, index) => {
                                  return (
                                    field?.fieldLabel && (
                                      <th
                                        key={index}
                                        colspan="1"
                                        role="columnheader"
                                        className={`p-3 font-bold `}
                                      >
                                        {field?.fieldLabel}
                                      </th>
                                    )
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {orderData?.orderLineList?.map((row, i) => (
                                <tr role="row" key={i}>
                                  {orderData?.fields?.map((field, index) => {
                                    return (
                                      field?.fieldLabel && (
                                        <td
                                          key={index}
                                          role="cell"
                                          className="p-3"
                                        >
                                          {getFormatedData(
                                            field.fieldType,
                                            row[field?.fieldName],
                                            orderData?.currencySign
                                          )}
                                        </td>
                                      )
                                    );
                                  })}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={"2"} className="accItem mb-3">
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                      openAcc.includes("2") ? "rounded-t-lg" : "rounded-lg"
                    }`}
                  >
                    <div className="flex flex-row font-semibold text-sm accTitle">
                      <img src={FinanImg} alt="AddressImg" className="mr-2" />
                      Financial Information
                    </div>
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded ? (
                          <div className="accIconOpen inline-block mr-3" />
                        ) : (
                          <div className="accIconClose inline-block mr-3 rotate-45" />
                        )
                      }
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accPanel grid rounded-b-lg py-6 px-4">
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm">
                          Order Currency
                        </div>
                        <div className="fieldDetails font-medium text-sm ">
                          {orderData?.currencySign}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm">
                          Basic Order Value
                        </div>
                        <div className="fieldDetails font-medium text-sm ">
                          {numberFormat(
                            orderData?.totalPrice,
                            orderData?.currencySign
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm">
                          Tax Value
                        </div>
                        <div className="fieldDetails font-medium text-sm ">
                          {numberFormat(
                            orderData?.totalGST,
                            orderData?.currencySign
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm">
                          Total Order Value
                        </div>
                        <div className="fieldDetails font-medium text-sm ">
                          {numberFormat(
                            orderData?.grandTotal,
                            orderData?.currencySign
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={"3"} className="accItem mb-3">
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                      openAcc.includes("3") ? "rounded-t-lg" : "rounded-lg"
                    }`}
                  >
                    <div className="flex flex-row font-semibold text-sm accTitle">
                      <img src={AddressImg} alt="AddressImg" className="mr-2" />
                      Address Information
                    </div>
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded ? (
                          <div className="accIconOpen inline-block mr-3" />
                        ) : (
                          <div className="accIconClose inline-block mr-3 rotate-45" />
                        )
                      }
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accPanel grid rounded-b-lg py-5 px-4">
                    <div className="grid grid-cols-2">
                      <div className="grid grid-cols-2 pb-2 pl-0 px-3 mr-10">
                        <div className="mr-10">
                          <div className="fieldName font-normal text-sm mb-2">
                            Billing Address :
                          </div>
                          <div className="fieldDetails font-medium text-sm">
                            <div className="flex flex-row rounded-lg p-4 addressData">
                              {orderData?.billingStreet},<br />
                              {orderData?.billingCity},{" "}
                              {orderData?.billingPostalCode}
                              ,
                              <br />
                              {orderData?.billingState},<br />
                              {orderData?.billingCountry}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="fieldName font-normal text-sm mb-2">
                            Shipping Address :
                          </div>
                          <div className="fieldDetails font-medium text-sm">
                            <div className="flex flex-row rounded-lg p-4 addressData">
                              {orderData?.shippingStreet},<br />
                              {orderData?.shippingCity},{" "}
                              {orderData?.shippingPostalCode}
                              ,<br />
                              {orderData?.shippingState},<br />
                              {orderData?.shippingCountry}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={"4"} className="accItem mb-3">
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                      openAcc.includes("4") ? "rounded-t-lg" : "rounded-lg"
                    }`}
                  >
                    <div className="flex flex-row font-semibold text-sm accTitle">
                      <img src={AdditImg} alt="SystemImg" className="mr-2" />
                      Additional Information
                    </div>
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded ? (
                          <div className="accIconOpen inline-block mr-3" />
                        ) : (
                          <div className="accIconClose inline-block mr-3 rotate-45" />
                        )
                      }
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accPanel grid rounded-b-lg py-6 px-4">
                    <div className="grid grid-cols-4 fieldWrapper pb-2">
                      <div className="fieldName font-normal text-sm">
                        Description
                      </div>
                      <div className="fieldDetails font-medium text-sm  col-span-3">
                        {orderData?.description}
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              {/* {orderData?.orderLineList?.length > 0 ? (
                <AccordionItem uuid={"5"} className="accItem mb-3">
                  <AccordionItemHeading>
                    <AccordionItemButton
                      className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                        openAcc.includes("5") ? "rounded-t-lg" : "rounded-lg"
                      }`}
                    >
                      <div className="flex flex-row font-semibold text-sm accTitle">
                        <img src={Order2Img} alt="SystemImg" className="mr-2" />
                        Order Items
                      </div>
                      <AccordionItemState>
                        {({ expanded }) =>
                          expanded ? (
                            <div className="accIconOpen inline-block mr-3" />
                          ) : (
                            <div className="accIconClose inline-block mr-3 rotate-45" />
                          )
                        }
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="accPanel rounded-b-lg py-6 px-4">
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              ) : null} */}
            </Accordion>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderDetails;
