import React, { useState, useEffect, useCallback } from "react";
import TableContainer from "../../components/table/TableContainer";
import ProdImg from "../../assets/products/product.svg";
import "./products.scss";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/table/pageHeader/PageHeader";
import useList from "../../controller/list/list-controller";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { debounce } from "debounce";
import { PER_PAGE } from "../../utils/constants";
import { buildColumns } from "../../utils/helper";

const Products = () => {
  const [search, setSearch] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [totalRec, setTotalRec] = useState(0);
  const [flag, setFlag] = useState(true);
  const [orderCol, setOrderCol] = useState("");
  const [orderType, setOrderType] = useState("");
  const navigate = useNavigate();
  const { getProductRecords, productsData, isLoading, errMsg } = useList();

  useEffect(() => {
    setFlag(false);
    getProductRecords({
      objectName: "Product",
      pageNo: 1,
      orderByField: "",
      orderByAPIField: "",
      orderDirection: "",
      searchKey: "",
    });
  }, []);

  useEffect(() => {
    setIsLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (errMsg) {
      toast.error(errMsg);
    }
  }, [errMsg]);

  useEffect(() => {
    const header = buildColumns(productsData?.fields);

    setColumns(header);
    setData(productsData?.records);
    (productsData?.totalRecordCount || productsData?.totalRecordCount === 0) &&
      setTotalRec(
        productsData?.totalRecordCount
          ? parseInt(productsData?.totalRecordCount) / PER_PAGE
          : 1
      );
    // (productsData?.pageNo || productsData?.pageNo === 0) &&
    //   setCurrPage(productsData?.pageNo - 1);
  }, [productsData]);

  useEffect(() => {
    if (orderCol || orderType) {
      setOrderCol("");
      setOrderType("");
    }
    if (!flag) getSearchDebounce(search);
  }, [search]);

  const getSearchDebounce = useCallback(
    debounce((searchData) => {
      getProductRecords({
        objectName: "Product",
        pageNo: 1,
        orderByField: "",
        orderByAPIField: "",
        orderDirection: "",
        searchKey: searchData,
      });
    }, 1000),
    []
  );

  const handleGetProducts = (page = 0, field = "", apiField = "", dir = "") => {
    let pageNo = page + 1;

    setCurrPage(page);
    getProductRecords({
      objectName: "Product",
      pageNo: pageNo,
      orderByField: field,
      orderByAPIField: apiField,
      orderDirection: dir,
      searchKey: search,
    });
  };

  const handleRowClick = (row) => {
    navigate(`/products/${row?.original?.productId}`);
  };

  return (
    <div className="product-container h-full flex flex-col">
      {isLoader && !data ? (
        <Loader />
      ) : data?.length > 0 || columns?.length > 0 ? (
        <>
          <PageHeader
            img={ProdImg}
            text="Products"
            isSearch={true}
            search={search}
            setSearch={setSearch}
          />
          <TableContainer
            data={data}
            columns={columns?.filter((col) => {
              return col?.fieldLabel;
            })}
            rowClick={handleRowClick}
            gotoPage={handleGetProducts}
            pageIndex={currPage}
            isLoading={isLoader}
            pageCount={totalRec}
            orderCol={orderCol}
            setOrderCol={setOrderCol}
            orderType={orderType}
            setOrderType={setOrderType}
          />
        </>
      ) : null}
    </div>
  );
};

export default Products;
