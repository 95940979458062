import { useState } from "react";
import axiosInstance from "../../utils/AxiosAPI";
import { getCookie } from "../../utils/session";

const useProduct = () => {
  const [caseDetail, setCaseDetail] = useState({});
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getCaseDetails = async (id) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);

    try {
      const response = await axiosInstance.post(`GetCaseDetail/`, {
        caseId: id,
      });
      if (response?.data?.data) {
        setSuccMsg(response?.data?.message);
        setCaseDetail(response?.data?.data);
      } else {
        setErrMsg(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  const createCase = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    const accountId = getCookie("accountId");

    try {
      const response = await axiosInstance.post(`CreateCase/`, {
        accountId,
        ...data,
      });
      if (response?.data?.data) {
        setSuccMsg(response?.data?.message);
      } else {
        setErrMsg(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  return {
    caseDetail,
    succMsg,
    errMsg,
    isLoading,
    getCaseDetails,
    createCase,
  };
};

export default useProduct;
