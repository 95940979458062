import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import FormInput from "../../components/form/FormInput";
import useAuth from "../../controller/auth/auth-controller";
import { getCookie } from "../../utils/session";
import "./login.scss";
import { validationSchema } from "./validationSchema";
import { toast } from "react-toastify";
import Button from "../../components/buttton/button";

const Login = () => {
  const authController = useAuth();

  const navigate = useNavigate();
  const { succMsg, isLoading, errMsg, login } = authController;

  useEffect(() => {
    const isLogin = getCookie("isLogin");
    if (isLogin) {
      navigate("/account");
    }
  }, []);

  useEffect(() => {
    const isLogin = getCookie("isLogin");
    if (succMsg && isLogin) {
      toast.success(succMsg);
      navigate("/account");
    }
  }, [succMsg]);

  useEffect(() => {
    if (errMsg) {
      toast.error(errMsg);
    }
  }, [errMsg]);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = values;

      login({ data });
    },
  });

  return (
    <div className="login-container h-screen flex justify-center items-center	bg-center bg-no-repeat bg-cover">
      <section className="wrapper flex flex-col	rounded p-10">
        <img src={Logo} alt="logo-icon" className="m-auto" />
        <form id="loginForm" onSubmit={formik.handleSubmit}>
          <div className="formWrapper my-8 mb-6">
            <div className="title mb-5">
              <p className="font-bold text-base uppercase	mb-1 ">
                Dealer Portal Login
              </p>
            </div>
            <FormInput
              label="Username"
              dom={{
                type: "text",
                name: "username",
                onChange: (e) => {
                  formik.setFieldValue("username", e.target.value, true);
                },
                value: formik.values.username,
                placeholder: "username",
                className: "p-3",
              }}
              required={true}
              error={formik.touched.username && Boolean(formik.errors.username)}
              errorMessage={formik.touched.username && formik.errors.username}
              wrapperClass="mb-4"
              fullwidth={true}
            />
            <FormInput
              label="Password"
              dom={{
                type: "password",
                name: "password",
                onChange: (e) => {
                  formik.setFieldValue("password", e.target.value, true);
                },
                value: formik.values.password,
                placeholder: "password",
                className: "p-3",
              }}
              required={true}
              error={formik.touched.password && Boolean(formik.errors.password)}
              errorMessage={formik.touched.password && formik.errors.password}
              wrapperClass="mb-4"
              fullwidth={true}
            />
            <div className="forgotPass flex justify-end">
              <Link to={"/reset-password"} className="font-medium	text-xs	">
                <p>Forgot password?</p>
              </Link>
            </div>
          </div>
          <Button loader={isLoading} className="h-12 text-sm" type="submit">
            Login
          </Button>
          {/* <button type="submit" className="btn btn-primary w-full py-4">

          </button> */}
        </form>
      </section>
    </div>
  );
};

export default Login;
