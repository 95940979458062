import * as yup from "yup";

export const validationSchema = yup.object({
  username: yup
    .string("Enter username")
    // .email("ENTER A VALID EMAIL")
    .required("Username is required"),
  newPassword: yup.string("Enter password").required("Password is required"),
  confPassword: yup
    .string()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.newPassword === value;
    }),
  securityQuestion: yup
    .string("Enter security question")
    .required("Security question is required"),
  securityAnswer: yup
    .string("Enter security answer")
    .required("Security answer is required"),
});
