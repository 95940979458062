import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "rc-tooltip/assets/bootstrap.css";
import "react-accessible-accordion/dist/fancy-example.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-modal-video/scss/modal-video.scss";
import "react-image-lightbox/style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./pages/notFound/NotFound";
import ProtectedRoute from "./pages/ProtectedRoute";
import SharedLayout from "./pages/sharedLayout/SharedLayout";
import Login from "./pages/login/Login";
import ResetPassword from "./pages/reset-password/ResetPassword";
import Accounts from "./pages/accounts/Accounts";
import FmiCases from "./pages/fmi-cases/FmiCases";
import FmiCaseDetails from "./pages/fmi-case-details/FmiCaseDetails";
import Products from "./pages/products/Products";
import Orders from "./pages/orders/Orders";
import ProductDetails from "./pages/product-details/ProductDetails";
import OrderDetails from "./pages/order-details/OrderDetails";
import OrderCreate from "./pages/order-create/OrderCreate";
import Faq from "./pages/faq/Faq";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route path="/account" element={<Accounts />} />
          <Route path="/fmi-cases" element={<FmiCases />} />
          <Route path="/fmi-cases/:cid" element={<FmiCaseDetails />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:pid" element={<ProductDetails />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/:oid" element={<OrderDetails />} />
          <Route path="/orders/create" element={<OrderCreate />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/logout" element={<Accounts />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        theme="colored"
        hideProgressBar
      />
    </BrowserRouter>
  );
}

export default App;
