import React from "react";
import { Navigate } from "react-router-dom";
// import useAuth from "../controller/auth/auth-controller";
import { getCookie } from "../utils/session";
import { useLocation } from "react-router";

const ProtectedRoute = (props) => {
  // const authController = useAuth();
  const isLogin = getCookie("isLogin");
  // const { isLogin } = authController.authData;

  const location = useLocation();
  const { pathname } = location;

  if (!isLogin) {
    return <Navigate to="/login" />;
  }

  if (pathname === "/") {
    window.location.href = "/account";
  }
  return props.children;
};
export default ProtectedRoute;
