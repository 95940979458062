import React from "react";

function Account({ color = "black" }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.3137 4.68628C24.2917 1.66431 20.2737 0 16 0C11.7263 0 7.70825 1.66431 4.68628 4.68628C1.66431 7.70825 0 11.7263 0 16C0 20.2737 1.66431 24.2917 4.68628 27.3137C7.70825 30.3357 11.7263 32 16 32C20.2737 32 24.2917 30.3357 27.3137 27.3137C30.3357 24.2917 32 20.2737 32 16C32 11.7263 30.3357 7.70825 27.3137 4.68628ZM6.94409 26.8311C7.47241 22.2852 11.3809 18.7754 16 18.7754C18.4351 18.7754 20.7249 19.7241 22.4473 21.4463C23.9023 22.9016 24.8201 24.8018 25.0562 26.8308C22.6025 28.8857 19.4434 30.125 16 30.125C12.5566 30.125 9.39771 28.886 6.94409 26.8311ZM16 16.8442C13.3206 16.8442 11.1404 14.6641 11.1404 11.9846C11.1404 9.30493 13.3206 7.125 16 7.125C18.6794 7.125 20.8596 9.30493 20.8596 11.9846C20.8596 14.6641 18.6794 16.8442 16 16.8442ZM26.6699 25.2458C26.1921 23.3196 25.1958 21.5435 23.7729 20.1206C22.6206 18.9683 21.2573 18.1038 19.7705 17.562C21.5579 16.3499 22.7346 14.302 22.7346 11.9846C22.7346 8.27124 19.7134 5.25 16 5.25C12.2866 5.25 9.26538 8.27124 9.26538 11.9846C9.26538 14.3032 10.4431 16.3518 12.2319 17.5637C10.864 18.0623 9.59814 18.833 8.50903 19.8477C6.93188 21.3164 5.83667 23.1892 5.32886 25.2444C3.17822 22.7651 1.875 19.532 1.875 16C1.875 8.21143 8.21143 1.875 16 1.875C23.7886 1.875 30.125 8.21143 30.125 16C30.125 19.5327 28.8213 22.7666 26.6699 25.2458Z"
        fill={color}
      />
    </svg>
  );
}

export default Account;
