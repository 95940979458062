import cookie from "js-cookie";

export const setCookie = (key, value) => {
  cookie.set(key, value, {
    // expires: 1,
    path: "/",
  });
};

export const removeCookie = (key) => {
  cookie.remove(key);
};

export const getCookie = (key, req) => {
  let token = cookie.get(key);
  return token;
};

export const getCookiees = (key, req) => {
  return process.browser
    ? getCookieFromBrowser(key)
    : getCookieFromServer(key, req);
};

const getCookieFromBrowser = (key) => {
  return cookie.get(key);
};

const getCookieFromServer = (key, req) => {
  if (!req.headers || !req.headers.cookie) {
    return null;
  }
  const rawCookie = req.headers.cookie
    .split(";")
    .find((c) => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return null;
  }
  return rawCookie.split("=")[1];
};

export const forceLogout = () => {
  removeCookie("freemans-token");
  removeCookie("accountId");
  removeCookie("accountName");
  removeCookie("ownerId");
  removeCookie("customerType");
  removeCookie("currencyIsoCode");
  removeCookie("isLogin");
  removeCookie("instanceUrl");
  window.location.href = "/login";
  window.location.reload(true);
};
