import { useState } from "react";
import axiosInstance from "../../utils/AxiosAPI";
import { getCookie } from "../../utils/session";

const useAccounts = () => {
  const [accData, setAccData] = useState({});
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getAccDetails = async () => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    const accountId = getCookie("accountId");

    try {
      const response = await axiosInstance.post(`FetchAccountContact/`, {
        accountId,
      });
      if (response?.data?.status) {
        setSuccMsg(response?.data?.message);
        setAccData(response?.data?.data);
      } else {
        setErrMsg(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  return {
    accData,
    succMsg,
    errMsg,
    isLoading,
    getAccDetails,
  };
};

export default useAccounts;
