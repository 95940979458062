import clsx from "clsx";
import React from "react";
import { FadeLoader } from "react-spinners";
import Spinner from "../loader/spinner";

export default function Button({
  className,
  type = "button",
  children,
  varients = "primary",
  loader = false,
  ...rest
}) {
  return (
    <button
      type={type}
      className={clsx(`btn btn-${varients} w-full`, className)}
      {...rest}
    >
      {loader ? (
        <div className="loaderInf">
          <Spinner color={varients === "primary" ? "white" : "black"} />
        </div>
      ) : (
        children
      )}
    </button>
  );
}
