import React from "react";

const NotFound = () => {
  return (
    <div>
      <h1>Url Not Found</h1>
    </div>
  );
};
export default NotFound;
