import axios from "axios";
// import { toast } from "react-toastify";
import { forceLogout, getCookie } from "./session";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

axiosInstance.interceptors.request.use(function (config) {
  const basePath = getCookie("instanceUrl");
  const token = getCookie("freemans-token");

  config.baseURL = `${basePath}/services/apexrest/Portal/`;
  config.headers.authorization = token ? token : "";

  return config;
});

axiosInstance.defaults.validateStatus = function (status) {
  if (status === 401) {
    forceLogout();
  } else return true;
};

axiosInstance.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error?.code === "ERR_NETWORK") {
      forceLogout();
    }

    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
