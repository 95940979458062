import axios from "axios";
import { useState } from "react";
import axiosInstance from "../../utils/AxiosAPI";
import { LOGIN_BASE } from "../../utils/constants";
import { forceLogout, setCookie, getCookie } from "../../utils/session";

const initialState = {
  accountId: "",
  isLogin: false,
  token: "",
  tokenType: "",
  instanceUrl: "",
};

const useAuth = () => {
  const [authData, setAuthData] = useState(initialState);
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const login = async ({ data }) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${LOGIN_BASE}/api/v1/auth/login`,
        data
      );
      if (response?.data?.status) {
        setCookie(
          "freemans-token",
          `${response?.data?.data?.tokenType} ${response?.data?.data?.token}`
        );
        setCookie("accountId", response?.data?.data?.accountId);
        setCookie(
          "accountName",
          response?.data?.data?.accountData?.accountName
        );
        setCookie("ownerId", response?.data?.data?.accountData?.ownerId);
        setCookie(
          "customerType",
          response?.data?.data?.accountData?.customerType
        );
        setCookie(
          "currencyIsoCode",
          response?.data?.data?.accountData?.currencyIsoCode
        );
        setCookie("isLogin", true);
        setCookie("instanceUrl", response?.data?.data?.instanceUrl);

        setAuthData({
          accountId: response?.data?.data?.accountId,
          isLogin: true,
          token: response?.data?.data?.token,
          tokenType: response?.data?.data?.tokenType,
          instanceUrl: response?.data?.data?.instanceUrl,
        });
        setSuccMsg(response?.data?.message);
        setIsLoading(false);
      } else {
        setErrMsg(response?.data?.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  const resetPassword = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    try {
      const response = await axios.post(
        "http://3.112.72.183:5000/api/v1/auth/forgot-password/",
        data
      );

      response?.data?.status
        ? setSuccMsg(response?.data?.message)
        : setErrMsg(response?.data?.message);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  const logout = () => {
    setAuthData(initialState);
    forceLogout();
  };

  const changePassword = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    const accountId = getCookie("accountId");

    try {
      const response = await axiosInstance.post("GeneratePassword/", {
        accountId,
        ...data,
      });

      response?.data?.status
        ? setSuccMsg(response?.data?.message)
        : setErrMsg(response?.data?.message);
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  return {
    authData,
    succMsg,
    errMsg,
    isLoading,
    // isUpdating,
    login,
    resetPassword,
    logout,
    changePassword,
  };
};

export default useAuth;
