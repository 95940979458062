import React from "react";

function Product({ color = "black" }) {
  return (
    <svg
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.3596 15.36C31.3596 23.8294 24.4691 30.72 15.9996 30.72C7.53021 30.72 0.639648 23.8294 0.639648 15.36C0.639648 14.8294 1.06909 14.4 1.59965 14.4C2.13021 14.4 2.55965 14.8294 2.55965 15.36C2.55965 22.7709 8.58877 28.8 15.9996 28.8C23.4105 28.8 29.4396 22.7709 29.4396 15.36C29.4396 7.94912 23.4105 1.92 15.9996 1.92C12.4275 1.92 9.11133 3.30848 6.61661 5.76H10.2396C10.7702 5.76 11.1996 6.18944 11.1996 6.72C11.1996 7.25056 10.7702 7.68 10.2396 7.68H4.47965C3.94909 7.68 3.51965 7.25056 3.51965 6.72V0.96C3.51965 0.42944 3.94909 0 4.47965 0C5.01021 0 5.43965 0.42944 5.43965 0.96V4.23488C8.27197 1.53152 11.9936 0 15.9996 0C24.4691 0 31.3596 6.89056 31.3596 15.36ZM24.6396 11.52V20.16C24.6396 20.5603 24.3913 20.9184 24.0172 21.0592L16.3372 23.9392C16.2284 23.9795 16.1139 24 15.9996 24C15.8854 24 15.7708 23.9795 15.662 23.9392L7.98205 21.0592C7.60797 20.9184 7.35965 20.5603 7.35965 20.16V11.52C7.35965 11.1197 7.60797 10.7616 7.98205 10.6208L15.662 7.7408C15.8796 7.66016 16.1196 7.66016 16.3369 7.7408L24.0169 10.6208C24.3913 10.7616 24.6396 11.1197 24.6396 11.52ZM11.0534 11.52L15.9996 13.3744L20.9459 11.52L15.9996 9.6656L11.0534 11.52ZM9.27965 19.4944L15.0396 21.6544V15.0656L9.27965 12.9056V19.4944ZM22.7196 19.4944V12.9056L16.9596 15.0656V21.6544L22.7196 19.4944Z"
        fill={color}
      />
    </svg>
  );
}

export default Product;
