import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./sidebar.scss";
import Account from "../../assets/sidebar/account";
import FmiCases from "../../assets/sidebar/fmiCases";
import Orders from "../../assets/sidebar/orders";
import Product from "../../assets/sidebar/product";
import Contact from "../../assets/sidebar/contact";
import Faq from "../../assets/sidebar/faq";
import { useDialogContext } from "../dialogs/Dialog";

const data = [
  {
    icon: (color) => <Account color={color} />,
    title: "My Account",
    link: "/account",
  },
  {
    icon: (color) => <FmiCases color={color} />,
    title: "FMI Cases",
    link: "/fmi-cases",
  },
  {
    icon: (color) => <Product color={color} />,
    title: "Products",
    link: "/products",
  },
  {
    icon: (color) => <Orders color={color} />,
    title: "Orders",
    link: "/orders",
  },
  {
    icon: (color) => <Contact color={color} />,
    title: "Contact Us",
  },
  {
    icon: (color) => <Faq color={color} />,
    title: "FAQ",
    link: "/faq",
  },
];

const Sidebar = ({ pathname }) => {
  const { dispatch } = useDialogContext();
  const [openMenu, setMenu] = useState("");

  useEffect(() => {
    const pathArr = pathname.split("/");
    setMenu(`/${pathArr[1].replace(/\s+/g, "-").toLowerCase()}`);
  }, [pathname]);

  return (
    <div id="sidebar" className={`overflow-y-auto py-4 pt-0 px-2.5`}>
      <ul className="flex flex-col items-center">
        {data.map((sideItem, i) => {
          return sideItem?.link ? (
            <Link to={sideItem.link} className="w-full" key={i}>
              <li
                className={`${
                  openMenu && openMenu === sideItem.link ? "active" : ""
                } rounded-lg w-full menuItem font-medium text-sm py-3	 flex flex-col items-center justify-center text-center`}
                onClick={() => setMenu(sideItem.link)}
              >
                {sideItem.icon(
                  openMenu && openMenu === sideItem.link ? "#FFFFFF" : "#000000"
                )}
                {sideItem.title}
              </li>
            </Link>
          ) : (
            <li
              key={i}
              className={`${
                openMenu && openMenu === sideItem.link ? "active" : ""
              } rounded-lg w-full menuItem font-medium text-sm py-3	 flex flex-col items-center justify-center text-center cursor-pointer`}
              onClick={() => {
                dispatch({
                  type: "openDialog",
                  payload: {
                    data: {
                      modalType: "changePassword",
                      title: "Contact Us",
                      className: "pb-0",
                    },
                    path: `contact-us/ContactUs`,
                  },
                });
              }}
            >
              {sideItem.icon(
                openMenu && openMenu === sideItem.link ? "#FFFFFF" : "#000000"
              )}
              {sideItem.title}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
