import React from "react";

function Contact({ color = "black" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
    >
      <path
        d="M18.2743 0H4.77143C3.19374 0.000731429 1.91483 1.27945 1.91429 2.85714V4.09143H0.571429C0.255726 4.09143 0 4.34734 0 4.66286C0 4.97838 0.255726 5.23429 0.571429 5.23429H1.90839V8.98286H0.571429C0.255726 8.98286 0 9.23858 0 9.55429C0 9.86981 0.255726 10.1257 0.571429 10.1257H1.90839V13.8743H0.571429C0.255726 13.8743 0 14.1302 0 14.4457C0 14.7614 0.255726 15.0171 0.571429 15.0171H1.90839V18.7657H0.571429C0.255726 18.7657 0 19.0216 0 19.3371C0 19.6527 0.255726 19.9086 0.571429 19.9086H1.90839V21.1429C1.90912 22.7205 3.18784 23.9993 4.76553 24H18.2684C19.8461 23.9993 21.1252 22.7205 21.1256 21.1429V2.85714C21.1279 1.28055 19.8509 0.00105143 18.2743 0ZM19.9886 21.1429C19.9857 22.0884 19.2198 22.8544 18.2743 22.8571H4.77143C3.82587 22.8544 3.05984 22.0884 3.05714 21.1429V19.9086H4.3941C4.70981 19.9086 4.96553 19.6527 4.96553 19.3371C4.96553 19.0216 4.70981 18.7657 4.3941 18.7657H3.05714V15.0171H4.3941C4.70981 15.0171 4.96553 14.7614 4.96553 14.4457C4.96553 14.1302 4.70981 13.8743 4.3941 13.8743H3.05714V10.1257H4.3941C4.70981 10.1257 4.96553 9.86981 4.96553 9.55429C4.96553 9.23858 4.70981 8.98286 4.3941 8.98286H3.05714V5.23429H4.3941C4.70981 5.23429 4.96553 4.97838 4.96553 4.66286C4.96553 4.34734 4.70981 4.09143 4.3941 4.09143H3.05714V2.85714C3.05984 1.91159 3.82587 1.14555 4.77143 1.14286H18.2743C19.2198 1.14555 19.9857 1.91159 19.9886 2.85714V21.1429Z"
        fill={color}
      />
      <path
        d="M11.5195 11.8344C13.05 11.8344 14.2909 10.5937 14.2909 9.06293C14.2909 7.53219 13.05 6.2915 11.5195 6.2915C9.98878 6.2915 8.74805 7.53219 8.74805 9.06293C8.74947 10.5929 9.98929 11.8329 11.5195 11.8344ZM11.5195 7.42865C12.4188 7.42865 13.1481 8.15793 13.1481 9.05722C13.1481 9.95651 12.4188 10.6858 11.5195 10.6858C10.62 10.6858 9.89091 9.95651 9.89091 9.05722C9.89251 8.15866 10.6205 7.43025 11.5195 7.42865ZM11.5195 12.7544C9.14234 12.7544 7.2793 14.6801 7.2793 17.1429C7.28021 17.4581 7.53557 17.7134 7.85073 17.7144H15.1881C15.5032 17.7134 15.7586 17.4581 15.7595 17.1429C15.7595 14.6801 13.8964 12.7544 11.5195 12.7544ZM8.46787 16.5715C8.71377 15.0115 9.94234 13.8972 11.5195 13.8972C13.0964 13.8972 14.3309 15.0115 14.5709 16.5715H8.46787Z"
        fill={color}
      />
    </svg>
  );
}

export default Contact;
