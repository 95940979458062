import React, { useState, useEffect } from "react";
import "./accounts.scss";
import AccImg from "../../assets/accounts/account.svg";
import AddressImg from "../../assets/accounts/address.svg";
import CommImg from "../../assets/accounts/comm.svg";
import ContactImg from "../../assets/accounts/contact.svg";
import InfoImg from "../../assets/accounts/info.svg";
import KycImg from "../../assets/accounts/kyc.svg";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import PageHeader from "../../components/table/pageHeader/PageHeader";
import useAccounts from "../../controller/accounts/accounts-controller";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import {
  formateDate,
  getNameFirstLetter,
  numberFormat,
} from "../../utils/helper";
import { useDialogContext } from "../../components/dialogs/Dialog";

const Accounts = () => {
  const { getAccDetails, accData, isLoading, errMsg } = useAccounts();
  const [isLoader, setIsLoader] = useState(false);
  const [accInfo, setAccInfo] = useState({});
  const [openAcc, setOpenAcc] = useState(["0", "1", "2", "3", "4"]);
  const { dispatch } = useDialogContext();

  useEffect(() => {
    getAccDetails();
  }, []);

  useEffect(() => {
    setAccInfo(accData);
  }, [accData]);

  useEffect(() => {
    setIsLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (errMsg) {
      toast.error(errMsg);
    }
  }, [errMsg]);

  return (
    <div className="accounts-container h-full flex flex-col">
      {isLoader ? (
        <div className="flex h-full  justify-center align-center">
          <Loader />
        </div>
      ) : (
        <>
          <PageHeader
            img={AccImg}
            text="My Account"
            isSubImg={true}
            isCreate={true}
            createText={"Change Password"}
            onCreateClick={() =>
              dispatch({
                type: "openDialog",
                payload: {
                  data: {
                    modalType: "changePassword",
                    title: "Change Password",
                    className: "pb-0",
                  },
                  path: `change-password/ChangePassword`,
                },
              })
            }
          />
          <div className="accordianWrapper">
            <Accordion
              allowMultipleExpanded={true}
              allowZeroExpanded={true}
              preExpanded={["0", "1", "2", "3", "4"]}
              onChange={(item) => setOpenAcc(item)}
            >
              <AccordionItem uuid={"0"} className="accItem mb-3">
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                      openAcc.includes("0") ? "rounded-t-lg" : "rounded-lg"
                    }`}
                  >
                    <div className="flex flex-row font-semibold text-sm accTitle">
                      <img src={InfoImg} alt="infoImg" className="mr-2" />
                      Basic Information
                    </div>
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded ? (
                          <div className="accIconOpen inline-block mr-3" />
                        ) : (
                          <div className="accIconClose inline-block mr-3 rotate-45" />
                        )
                      }
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accPanel grid rounded-b-lg py-5 px-4">
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3 mr-10">
                        <div className="fieldName font-normal text-sm">
                          Account Name
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {accInfo?.accountName}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3">
                        <div className="fieldName font-normal text-sm">
                          Email
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {accInfo?.email}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3 mr-10">
                        <div className="fieldName font-normal text-sm">
                          Phone
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {accInfo?.Phone}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3">
                        <div className="fieldName font-normal text-sm">
                          Account Owner
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          <div className="flex  flex-row items-center userData">
                            {/* <img
                          src={accInfo?.accOwnerImg}
                          alt="user"
                          className="rounded-full mr-2"
                        /> */}
                            {accInfo?.ownerName && (
                              <div className="rounded-full bg-black mr-2 flex justify-center items-center">
                                {getNameFirstLetter(accInfo?.ownerName)}
                              </div>
                            )}
                            {accInfo?.ownerName}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3 mr-10">
                        <div className="fieldName font-normal text-sm">
                          Field Sales Officer
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {accInfo?.fieldSalesOfficer}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3">
                        <div className="fieldName font-normal text-sm">
                          Commission Agent
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {accInfo?.agentName}
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={"1"} className="accItem mb-3">
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                      openAcc.includes("1") ? "rounded-t-lg" : "rounded-lg"
                    }`}
                  >
                    <div className="flex flex-row font-semibold text-sm accTitle">
                      <img src={KycImg} alt="infoImg" className="mr-2" />
                      KYC Information
                    </div>
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded ? (
                          <div className="accIconOpen inline-block mr-3" />
                        ) : (
                          <div className="accIconClose inline-block mr-3 rotate-45" />
                        )
                      }
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accPanel grid rounded-b-lg py-5 px-4">
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3 mr-10">
                        <div className="fieldName font-normal text-sm">
                          GSTIN
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {accInfo?.gstNo}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3">
                        <div className="fieldName font-normal text-sm">PAN</div>
                        <div className="fieldDetails font-medium text-sm">
                          {accInfo?.panNo}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3 mr-10">
                        <div className="fieldName font-normal text-sm">
                          Aadhar No.
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {accInfo?.aadharNo}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3">
                        <div className="fieldName font-normal text-sm">
                          Birthday
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {formateDate(accInfo?.birthday)}
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={"2"} className="accItem mb-3">
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                      openAcc.includes("2") ? "rounded-t-lg" : "rounded-lg"
                    }`}
                  >
                    <div className="flex flex-row font-semibold text-sm accTitle">
                      <img src={CommImg} alt="infoImg" className="mr-2" />
                      Commercial Information
                    </div>
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded ? (
                          <div className="accIconOpen inline-block mr-3" />
                        ) : (
                          <div className="accIconClose inline-block mr-3 rotate-45" />
                        )
                      }
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accPanel grid rounded-b-lg py-5 px-4">
                    <div className="grid grid-cols-2 mb-4">
                      {/* <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3 mr-10">
                        <div className="fieldName font-normal text-sm">
                          Doraha Unit Credit Limit
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {accInfo?.currencyIsoCode}{" "}
                          {numberFormat(
                            accInfo?.creditLimit,
                            accInfo?.currencyIsoCode
                          )}
                        </div>
                      </div> */}
                      <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3  mr-10">
                        <div className="fieldName font-normal text-sm">
                          Credit Limit
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {accInfo?.currencyIsoCode}{" "}
                          {numberFormat(
                            accInfo?.sapCreditLimit,
                            accInfo?.currencyIsoCode
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3">
                        <div className="fieldName font-normal text-sm">
                          Channel Partner Category
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {accInfo?.channelPartnerCategory}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2"></div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={"3"} className="accItem mb-3">
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                      openAcc.includes("3") ? "rounded-t-lg" : "rounded-lg"
                    }`}
                  >
                    <div className="flex flex-row font-semibold text-sm accTitle">
                      <img src={AddressImg} alt="infoImg" className="mr-2" />
                      Address Information
                    </div>
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded ? (
                          <div className="accIconOpen inline-block mr-3" />
                        ) : (
                          <div className="accIconClose inline-block mr-3 rotate-45" />
                        )
                      }
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accPanel grid rounded-b-lg py-5 px-4">
                    <div className="grid grid-cols-2">
                      <div className="grid grid-cols-2 pb-2 pl-0 px-3 mr-10">
                        <div className="mr-10">
                          <div className="fieldName font-normal text-sm mb-2">
                            Billing Address :
                          </div>
                          <div className="fieldDetails font-medium text-sm">
                            <div className="flex flex-row rounded-lg p-4 addressData">
                              {accInfo?.billingStreet},<br />
                              {accInfo?.billingCity},{" "}
                              {accInfo?.billingPostalCode}
                              ,
                              <br />
                              {accInfo?.billingState},<br />
                              {accInfo?.billingCountry}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="fieldName font-normal text-sm mb-2">
                            Shipping Address :
                          </div>
                          <div className="fieldDetails font-medium text-sm">
                            <div className="flex flex-row rounded-lg p-4 addressData">
                              {accInfo?.shippingStreet},<br />
                              {accInfo?.shippingCity},{" "}
                              {accInfo?.shippingPostalCode}
                              ,<br />
                              {accInfo?.shippingState},<br />
                              {accInfo?.shippingCountry}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={"4"} className="accItem mb-3">
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                      openAcc.includes("4") ? "rounded-t-lg" : "rounded-lg"
                    }`}
                  >
                    <div className="flex flex-row font-semibold text-sm accTitle">
                      <img src={ContactImg} alt="infoImg" className="mr-2" />
                      Contact Person Information
                    </div>
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded ? (
                          <div className="accIconOpen inline-block mr-3" />
                        ) : (
                          <div className="accIconClose inline-block mr-3 rotate-45" />
                        )
                      }
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accPanel grid rounded-b-lg py-5 px-4">
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3 mr-10">
                        <div className="fieldName font-normal text-sm">
                          Contact Name
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {accInfo?.contactList?.[0]?.name}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3">
                        <div className="fieldName font-normal text-sm">
                          Contact Email ID
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {accInfo?.contactList?.[0]?.email}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3 mr-10">
                        <div className="fieldName font-normal text-sm">
                          Contact Mobile No.
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {accInfo?.contactList?.[0]?.mobile}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2 pl-0 px-3">
                        <div className="fieldName font-normal text-sm">
                          Contact Owner
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          <div className="fieldDetails font-medium text-sm">
                            <div className="flex  flex-row items-center userData">
                              {/* <img
                          src={accInfo?.accOwnerImg}
                          alt="user"
                          className="rounded-full mr-2"
                        /> */}
                              {accInfo?.ownerName && (
                                <div className="rounded-full bg-black mr-2 flex justify-center items-center">
                                  {getNameFirstLetter(
                                    accInfo?.contactList?.[0]?.ownerName
                                  )}
                                </div>
                              )}
                              {accInfo?.contactList?.[0]?.ownerName}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </>
      )}
    </div>
  );
};

export default Accounts;
