import React from "react";
import { FadeLoader } from "react-spinners";
import Preloader from "../assets/preloader.gif";
function Loader() {
  return (
    <div className="flex h-full items-center justify-center">
      <img width={80} src={Preloader}></img>
      {/* <FadeLoader color="#e11b22" /> */}
    </div>
  );
}

export default Loader;
