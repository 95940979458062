import React from "react";
import Filter from "../Filter";
import Search from "../Search";
import "./pageHeader.scss";
import { getAccountName } from "../../../utils/helper";
import { IoMdArrowRoundBack } from "react-icons/io";
import Button from "../../buttton/button";

function PageHeader(props) {
  const {
    isFilter = false,
    options = {},
    sort = "",
    setSort = () => {},
    isSearch = false,
    search = "",
    setSearch = () => {},
    onSearchChange = () => {},
    text = "",
    isCreate = false,
    createText = "",
    onCreateClick = () => {},
    isDelete = false,
    subTitle = "",
    deleteText = "",
    onDeleteClick = () => {},
    isBackBtn = false,
    onBackClick = () => {},
    isDownload = false,
    downloadText = "",
    onDownloadClick = () => {},
    isLoading,
  } = props;

  const name = getAccountName();
  return (
    <div className="card-header flex flex-row justify-between p-3 rounded-lg mb-3">
      <div className="flex flex-row items-center">
        <div className="title-img rounded	p-2 mr-2.5">
          <img src={props.img} alt="title-img" />
        </div>
        <div className="mr-4">
          <p className="text font-normal text-xs">{text}</p>
          <div className="flex flex-row items-center">
            <p className="title font-bold	text-sm">{subTitle || name}</p>
          </div>
        </div>
        {isFilter ? (
          <div className="mr-4">
            <Filter options={options} sort={sort} setSort={setSort} />
          </div>
        ) : null}
      </div>
      <div className="flex flex-row items-center">
        {isSearch ? (
          <div className="w-64">
            <Search
              search={search}
              setSearch={setSearch}
              onChange={onSearchChange}
            />
          </div>
        ) : null}
        {isDownload ? (
          <Button
            className="capitalize ml-4 w-fit"
            varients="secondary-outlined"
            onClick={onDownloadClick}
            disabled={isLoading}
            loader={isLoading}
          >
            {downloadText ? downloadText : "Download"}
          </Button>
        ) : null}
        {isCreate ? (
          <button
            className="btn btn-secondary-outlined capitalize ml-4"
            onClick={onCreateClick}
          >
            {createText ? createText : "New"}
          </button>
        ) : null}
        {isDelete ? (
          <button
            className="btn btn-primary-outlined capitalize ml-4"
            onClick={onDeleteClick}
          >
            {deleteText ? deleteText : "Delete"}
          </button>
        ) : null}
        {isBackBtn ? (
          <button
            type="button"
            className="btn btn-secondary-outlined capitalize ml-4"
            onClick={onBackClick}
            style={{ minWidth: 80 }}
          >
            <IoMdArrowRoundBack className="mr-1.5 scale-150" />
            Back
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default PageHeader;
