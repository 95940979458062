import React, { useState, useEffect } from "react";
import "./fmi-case-details.scss";
import AccImg from "../../assets/accounts/account.svg";
import InfoImg from "../../assets/accounts/info.svg";
import AdditionalImg from "../../assets/fmi-cases/additional.svg";
import DescriptionImg from "../../assets/fmi-cases/description.svg";
import FeedbackImg from "../../assets/fmi-cases/feedback.svg";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import StarRating from "react-star-ratings";
import PageHeader from "../../components/table/pageHeader/PageHeader";
import { formateDate, getNameFirstLetter } from "../../utils/helper";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import useCase from "../../controller/case/case-controller";
import moment from "moment";

const FmiCaseDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { getCaseDetails, isLoading, errMsg, caseDetail } = useCase();
  const [search, setSearch] = useState();
  const [openAcc, setOpenAcc] = useState(["0", "1", "2", "3"]);
  const [isLoader, setIsLoader] = useState(false);
  const [caseData, setCaseData] = useState({});

  useEffect(() => {
    getCaseDetails(params?.cid);
  }, []);

  useEffect(() => {
    setCaseData(caseDetail);
  }, [caseDetail]);

  useEffect(() => {
    setIsLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (errMsg) {
      toast.error(errMsg);
    }
  }, [errMsg]);

  // === yet to implement .. not getting in api response
  // Case Creation Date

  return (
    <div className="fmi-case-details-container h-full flex flex-col">
      {isLoader ? (
        <Loader />
      ) : (
        <>
          <PageHeader
            img={AccImg}
            text="FMI Case Details"
            subTitle={caseData?.caseNo}
            isCreate={false}
            isDelete={false}
            search={search}
            setSearch={setSearch}
            isBackBtn={true}
            onBackClick={() => navigate("/fmi-cases")}
          />
          <div className="accordianWrapper">
            <Accordion
              allowMultipleExpanded={true}
              allowZeroExpanded={true}
              preExpanded={["0", "1", "2", "3"]}
              onChange={(item) => setOpenAcc(item)}
            >
              <AccordionItem uuid={"0"} className="accItem mb-3">
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                      openAcc.includes("0") ? "rounded-t-lg" : "rounded-lg"
                    }`}
                  >
                    <div className="flex flex-row font-semibold text-sm accTitle">
                      <img src={InfoImg} alt="infoImg" className="mr-2" />
                      Basic Information
                    </div>
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded ? (
                          <div className="accIconOpen inline-block mr-3" />
                        ) : (
                          <div className="accIconClose inline-block mr-3 rotate-45" />
                        )
                      }
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accPanel grid rounded-b-lg py-5 px-4">
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm ">
                          FMI Case No
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {caseData?.caseNo}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm ">
                          Date of Complaint
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {formateDate(caseData?.dateOfComplaint)}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm ">
                          Account
                        </div>
                        <div className="fieldDetails font-medium text-sm userData">
                          {caseData?.accountName}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm ">
                          Account Email
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {caseData?.accountEmail}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm ">
                          Contact
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {caseData?.contactName}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm ">
                          Case Creation Date
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {formateDate(caseData?.createdDate)}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm ">
                          Status
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {caseData?.status}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm ">
                          Owner
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          <div className="flex flex-row items-center userData">
                            {caseData?.ownerName && (
                              <div className="rounded-full account-img mr-2 flex justify-center items-center">
                                {getNameFirstLetter(caseData?.ownerName)}
                              </div>
                            )}
                            {caseData?.ownerName}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={"1"} className="accItem mb-3">
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                      openAcc.includes("1") ? "rounded-t-lg" : "rounded-lg"
                    }`}
                  >
                    <div className="flex flex-row font-semibold text-sm accTitle">
                      <img
                        src={AdditionalImg}
                        alt="additionalImg"
                        className="mr-2"
                      />
                      Case Information
                    </div>
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded ? (
                          <div className="accIconOpen inline-block mr-3" />
                        ) : (
                          <div className="accIconClose inline-block mr-3 rotate-45" />
                        )
                      }
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accPanel grid rounded-b-lg py-5 px-4">
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm ">
                          Origin
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {caseData?.origin}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm ">
                          Type
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {caseData?.type}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm ">
                          Priority
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {caseData?.priority}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm ">
                          Sub Type
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {caseData?.subType}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-4 fieldWrapper pb-2 mb-4">
                      <div className="fieldName font-normal text-sm ">
                        Subject
                      </div>
                      <div className="fieldDetails font-medium text-sm col-span-3">
                        {caseData?.subject}
                      </div>
                    </div>
                    <div className="grid grid-cols-4 fieldWrapper pb-2">
                      <div className="fieldName font-normal text-sm ">
                        Description
                      </div>
                      <div className="fieldDetails font-medium text-sm col-span-3">
                        {caseData?.description}
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={"2"} className="accItem mb-3">
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                      openAcc.includes("2") ? "rounded-t-lg" : "rounded-lg"
                    }`}
                  >
                    <div className="flex flex-row font-semibold text-sm accTitle">
                      <img src={DescriptionImg} alt="webImg" className="mr-2" />
                      Additional Information
                    </div>
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded ? (
                          <div className="accIconOpen inline-block mr-3" />
                        ) : (
                          <div className="accIconClose inline-block mr-3 rotate-45" />
                        )
                      }
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accPanel grid rounded-b-lg py-5 px-4">
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm ">
                          Product
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {caseData?.product}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm ">
                          Invoice#
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {caseData?.invoiceName}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm ">
                          Product Quantity
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {caseData?.noOfPieces}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm ">
                          Transport Company Name
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {caseData?.transportCompanyName}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm ">
                          Credit Note Number
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {caseData?.creditNoteNumber}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm">
                          Credit Note Decision
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {caseData?.creditNoteDecision}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 mb-4">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm ">
                          Tracking Number
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {caseData?.trackingNumber || "NA"}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm ">
                          Reason for Unresolved / Low Case Rating
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          {caseData?.reasonUnresolved}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-4 fieldWrapper pb-2 mb-4">
                      <div className="fieldName font-normal text-sm ">
                        Corrective Action
                      </div>
                      <div className="fieldDetails font-medium text-sm col-span-3">
                        {caseData?.correctiveAction}
                      </div>
                    </div>
                    <div className="grid grid-cols-4 fieldWrapper pb-2">
                      <div className="fieldName font-normal text-sm ">
                        Root Cause
                      </div>
                      <div className="fieldDetails font-medium text-sm col-span-3">
                        {caseData?.rootCause}
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={"3"} className="accItem mb-3">
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                      openAcc.includes("3") ? "rounded-t-lg" : "rounded-lg"
                    }`}
                  >
                    <div className="flex flex-row font-semibold text-sm accTitle">
                      <img
                        src={FeedbackImg}
                        alt="feedbackImg"
                        className="mr-2"
                      />
                      Feedback Information
                    </div>
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded ? (
                          <div className="accIconOpen inline-block mr-3" />
                        ) : (
                          <div className="accIconClose inline-block mr-3 rotate-45" />
                        )
                      }
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accPanel grid rounded-b-lg py-5 px-4">
                    <div className="grid grid-cols-2">
                      <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                        <div className="fieldName font-normal text-sm ">
                          Feedback Submitted
                        </div>
                        <div className="text-primary font-medium text-sm">
                          {caseData?.feedbackSubmitted ? "Yes" : "No"}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 fieldWrapper pb-2">
                        <div className="fieldName font-normal text-sm ">
                          Feedback Rating
                        </div>
                        <div className="fieldDetails font-medium text-sm">
                          <StarRating
                            rating={caseData?.feedbackRating}
                            starRatedColor="#FBD513"
                            numberOfStars={5}
                            name="rating"
                            starDimension="20px"
                            starSpacing="3px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </>
      )}
    </div>
  );
};

export default FmiCaseDetails;
