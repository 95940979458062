import React from "react";

function Faq({ color = "black" }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2397_2708)">
        <path
          d="M28.2667 0H3.73333C2.74354 0.00112926 1.79461 0.394824 1.09472 1.09472C0.394824 1.79461 0.00112926 2.74354 0 3.73333L0 21.8667C0.00112926 22.8565 0.394824 23.8054 1.09472 24.5053C1.79461 25.2052 2.74354 25.5989 3.73333 25.6H5.33333V30.5979C5.33239 30.8502 5.39983 31.0981 5.52849 31.3152C5.65714 31.5323 5.84221 31.7105 6.06402 31.8308C6.28584 31.9512 6.53611 32.0092 6.78824 31.9987C7.04038 31.9882 7.28496 31.9096 7.496 31.7712L16.9899 25.6H28.2667C29.2565 25.5989 30.2054 25.2052 30.9053 24.5053C31.6052 23.8054 31.9989 22.8565 32 21.8667V3.73333C31.9989 2.74354 31.6052 1.79461 30.9053 1.09472C30.2054 0.394824 29.2565 0.00112926 28.2667 0ZM30.9333 21.8667C30.9325 22.5737 30.6513 23.2514 30.1514 23.7514C29.6514 24.2513 28.9737 24.5325 28.2667 24.5333H16.832C16.7288 24.5334 16.6278 24.5634 16.5413 24.6197L6.912 30.88C6.86267 30.9132 6.80515 30.9321 6.74576 30.9346C6.68638 30.9371 6.62745 30.9232 6.57547 30.8944C6.52151 30.8662 6.4765 30.8235 6.4455 30.7711C6.4145 30.7187 6.39874 30.6587 6.4 30.5979V25.0667C6.4 24.9252 6.34381 24.7896 6.24379 24.6895C6.14377 24.5895 6.00812 24.5333 5.86667 24.5333H3.73333C3.02635 24.5325 2.34856 24.2513 1.84865 23.7514C1.34874 23.2514 1.06751 22.5737 1.06667 21.8667V3.73333C1.06751 3.02635 1.34874 2.34856 1.84865 1.84865C2.34856 1.34874 3.02635 1.06751 3.73333 1.06667H28.2667C28.9737 1.06751 29.6514 1.34874 30.1514 1.84865C30.6513 2.34856 30.9325 3.02635 30.9333 3.73333V21.8667Z"
          fill={color}
        />
        <path
          d="M4.79993 15.4667C4.94138 15.4667 5.07704 15.4105 5.17706 15.3105C5.27708 15.2105 5.33327 15.0748 5.33327 14.9334V10.1334H8.53327C8.67472 10.1334 8.81037 10.0772 8.91039 9.97719C9.01041 9.87717 9.0666 9.74151 9.0666 9.60007C9.0666 9.45862 9.01041 9.32296 8.91039 9.22294C8.81037 9.12292 8.67472 9.06673 8.53327 9.06673H5.33327V4.80007H10.6666C10.8081 4.80007 10.9437 4.74387 11.0437 4.64386C11.1437 4.54384 11.1999 4.40818 11.1999 4.26673C11.1999 4.12528 11.1437 3.98963 11.0437 3.88961C10.9437 3.78959 10.8081 3.7334 10.6666 3.7334H4.79993C4.65849 3.7334 4.52283 3.78959 4.42281 3.88961C4.32279 3.98963 4.2666 4.12528 4.2666 4.26673V14.9334C4.2666 15.0748 4.32279 15.2105 4.42281 15.3105C4.52283 15.4105 4.65849 15.4667 4.79993 15.4667Z"
          fill={color}
        />
        <path
          d="M12.2667 15.4667C12.4082 15.4667 12.5438 15.4105 12.6439 15.3105C12.7439 15.2105 12.8001 15.0748 12.8001 14.9334V10.6667H18.1334V14.9334C18.1334 15.0748 18.1896 15.2105 18.2896 15.3105C18.3896 15.4105 18.5253 15.4667 18.6667 15.4667C18.8082 15.4667 18.9438 15.4105 19.0439 15.3105C19.1439 15.2105 19.2001 15.0748 19.2001 14.9334V7.46673C19.2001 6.47659 18.8067 5.527 18.1066 4.82687C17.4065 4.12673 16.4569 3.7334 15.4667 3.7334C14.4766 3.7334 13.527 4.12673 12.8269 4.82687C12.1267 5.527 11.7334 6.47659 11.7334 7.46673V14.9334C11.7334 15.0748 11.7896 15.2105 11.8896 15.3105C11.9896 15.4105 12.1253 15.4667 12.2667 15.4667ZM15.4667 4.80007C16.1737 4.80091 16.8515 5.08214 17.3514 5.58205C17.8513 6.08196 18.1326 6.75975 18.1334 7.46673V9.60007H12.8001V7.46673C12.8009 6.75975 13.0821 6.08196 13.582 5.58205C14.082 5.08214 14.7597 4.80091 15.4667 4.80007Z"
          fill={color}
        />
        <path
          d="M24.5331 15.4667C25.3383 15.4667 26.1216 15.2046 26.7646 14.7201L27.3561 15.3115C27.4567 15.4087 27.5914 15.4624 27.7312 15.4612C27.8711 15.46 28.0048 15.4039 28.1037 15.305C28.2026 15.2062 28.2587 15.0724 28.2599 14.9325C28.2611 14.7927 28.2074 14.658 28.1102 14.5574L27.5198 13.9649C28.0043 13.3218 28.2664 12.5386 28.2665 11.7334V7.46673C28.2665 6.47659 27.8731 5.527 27.173 4.82687C26.4729 4.12673 25.5233 3.7334 24.5331 3.7334C23.543 3.7334 22.5934 4.12673 21.8933 4.82687C21.1931 5.527 20.7998 6.47659 20.7998 7.46673V11.7334C20.8009 12.7232 21.1946 13.6721 21.8945 14.372C22.5944 15.0719 23.5433 15.4656 24.5331 15.4667ZM21.8665 7.46673C21.8665 6.75949 22.1474 6.08121 22.6475 5.58111C23.1476 5.08102 23.8259 4.80007 24.5331 4.80007C25.2404 4.80007 25.9187 5.08102 26.4188 5.58111C26.9189 6.08121 27.1998 6.75949 27.1998 7.46673V11.7334C27.1997 12.2559 27.0451 12.7667 26.7555 13.2017L25.9769 12.423C25.8763 12.3258 25.7416 12.2721 25.6017 12.2733C25.4619 12.2745 25.3281 12.3306 25.2292 12.4295C25.1303 12.5284 25.0743 12.6621 25.073 12.802C25.0718 12.9418 25.1256 13.0765 25.2227 13.1771L26.0014 13.9558C25.6 14.2223 25.1338 14.3748 24.6525 14.3972C24.1712 14.4196 23.6928 14.3109 23.2684 14.0828C22.844 13.8547 22.4895 13.5157 22.2426 13.102C21.9957 12.6882 21.8657 12.2152 21.8665 11.7334V7.46673Z"
          fill={color}
        />
        <path
          d="M28.2669 18.1333H3.73353C3.59208 18.1333 3.45642 18.1895 3.35641 18.2895C3.25639 18.3895 3.2002 18.5252 3.2002 18.6666C3.2002 18.8081 3.25639 18.9437 3.35641 19.0438C3.45642 19.1438 3.59208 19.2 3.73353 19.2H28.2669C28.4083 19.2 28.544 19.1438 28.644 19.0438C28.744 18.9437 28.8002 18.8081 28.8002 18.6666C28.8002 18.5252 28.744 18.3895 28.644 18.2895C28.544 18.1895 28.4083 18.1333 28.2669 18.1333Z"
          fill={color}
        />
        <path
          d="M22.4002 20.7998H3.73353C3.59208 20.7998 3.45642 20.856 3.35641 20.956C3.25639 21.056 3.2002 21.1917 3.2002 21.3331C3.2002 21.4746 3.25639 21.6102 3.35641 21.7103C3.45642 21.8103 3.59208 21.8665 3.73353 21.8665H22.4002C22.5416 21.8665 22.6773 21.8103 22.7773 21.7103C22.8773 21.6102 22.9335 21.4746 22.9335 21.3331C22.9335 21.1917 22.8773 21.056 22.7773 20.956C22.6773 20.856 22.5416 20.7998 22.4002 20.7998Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2397_2708">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Faq;
