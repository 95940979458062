import React from "react";
import ReactPaginate from "react-paginate";
import FirstImg from "../../assets/first-icon.js";
import PrevImg from "../../assets/prev-icon.js";
import "./table.scss";

function Pagination(props) {
  const { canPreviousPage, canNextPage, pageCount, gotoPage, pageIndex } =
    props;

  let pages = Math.ceil(pageCount);
  return (
    <>
      <button
        onClick={() => gotoPage(0)}
        disabled={pageIndex === 0}
        className="firstBtn ml-1.5 flex items-center justify-center rounded-lg"
      >
        <FirstImg />
      </button>
      <ReactPaginate
        forcePage={pageIndex}
        nextLabel={<PrevImg />}
        previousLabel={<PrevImg />}
        onPageChange={(event) => gotoPage(event.selected)}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        pageCount={pages}
        pageClassName="ml-1.5 cursor-pointer rounded-lg"
        pageLinkClassName="h-full w-full flex items-center justify-center"
        previousClassName="prevBtn ml-1.5 cursor-pointer rounded-lg"
        previousLinkClassName="h-full w-full flex items-center justify-center"
        nextClassName="rotate-180 prevBtn ml-1.5 cursor-pointer rounded-lg"
        nextLinkClassName="h-full w-full flex items-center justify-center"
        breakLabel="..."
        breakClassName="ml-1.5 cursor-pointer rounded-lg"
        breakLinkClassName="h-full w-full flex items-center justify-center"
        containerClassName="pagination-container flex flex-row"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
      <button
        onClick={() => gotoPage(pages - 1)}
        disabled={pageIndex === pages - 1}
        className="rotate-180 firstBtn ml-1.5 flex items-center justify-center rounded-lg"
      >
        <FirstImg />
      </button>
    </>
  );
}

export default Pagination;
