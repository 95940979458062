import React, { useState, useEffect } from "react";
import "../accounts/accounts.scss";
import FaqImg from "../../assets/faq.svg";
import InfoImg from "../../assets/accounts/info.svg";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import PageHeader from "../../components/table/pageHeader/PageHeader";
import useList from "../../controller/list/list-controller";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

const Faq = () => {
  const { faqList, faqData, isLoading, errMsg } = useList();
  const [isLoader, setIsLoader] = useState(false);
  const [faqInfo, setFaqInfo] = useState([]);
  const [openAcc, setOpenAcc] = useState([]);

  useEffect(() => {
    faqList();
  }, []);

  useEffect(() => {
    setFaqInfo(faqData);
  }, [faqData]);

  useEffect(() => {
    setIsLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (errMsg) {
      toast.error(errMsg);
    }
  }, [errMsg]);

  return (
    <div className="accounts-container h-full flex flex-col">
      {isLoader ? (
        <div className="flex h-full  justify-center align-center">
          <Loader />
        </div>
      ) : (
        <>
          <PageHeader
            img={FaqImg}
            text="FAQ"
            subTitle="Frequently Asked Questions"
            isSubImg={true}
            isCreate={false}
          />
          <div className="accordianWrapper">
            <Accordion
              allowMultipleExpanded={false}
              allowZeroExpanded={true}
              preExpanded={[]}
              onChange={(item) => setOpenAcc(item)}
            >
              {faqInfo?.length > 0
                ? faqInfo?.map((dt, i) => (
                    <AccordionItem
                      uuid={`${i}`}
                      className="accItem mb-3"
                      key={i}
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton
                          className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                            openAcc.includes(`${i}`)
                              ? "rounded-t-lg"
                              : "rounded-lg"
                          }`}
                        >
                          <div className="flex flex-row items-center font-semibold text-sm accTitle">
                            <img src={InfoImg} alt="infoImg" className="mr-2" />
                            {dt?.category}
                          </div>
                          <AccordionItemState>
                            {({ expanded }) =>
                              expanded ? (
                                <div className="accIconOpen inline-block mr-3" />
                              ) : (
                                <div className="accIconClose inline-block mr-3 rotate-45" />
                              )
                            }
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="accPanel grid rounded-b-lg py-5 px-4">
                          <Accordion
                            allowMultipleExpanded={false}
                            allowZeroExpanded={true}
                            preExpanded={[]}
                          >
                            {dt?.faqDetails?.length > 0
                              ? dt?.faqDetails?.map((dt2, i2) => (
                                  <AccordionItem
                                    uuid={`${i2}`}
                                    className={`accItem ${
                                      i2 === dt?.faqDetails?.length - 1
                                        ? ""
                                        : "mb-3"
                                    }`}
                                    key={i2}
                                  >
                                    <AccordionItemHeading>
                                      <AccordionItemButton
                                        className={`accButton py-4 px-4 flex flex-row justify-between items-center`}
                                      >
                                        <div className="flex flex-row items-center font-semibold text-sm accTitle">
                                          {dt2?.question}
                                        </div>
                                        <AccordionItemState>
                                          {({ expanded }) =>
                                            expanded ? (
                                              <div className="accIconOpen inline-block mr-3" />
                                            ) : (
                                              <div className="accIconClose inline-block mr-3 rotate-45" />
                                            )
                                          }
                                        </AccordionItemState>
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      <div className="accPanel py-5 px-4">
                                        <div
                                          className="fieldName font-normal text-sm"
                                          dangerouslySetInnerHTML={{
                                            __html: dt2?.answer,
                                          }}
                                        />
                                      </div>
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                ))
                              : null}
                          </Accordion>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))
                : null}
            </Accordion>
          </div>
        </>
      )}
    </div>
  );
};

export default Faq;
