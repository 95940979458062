import React from "react";
import FormSelect from "../form/FormSelect";
import "./table.scss";

function Filter(props) {
  const { options, sort, setSort } = props;

  return (
    <FormSelect
      name="sorting"
      onChange={(e) => {
        if (e) setSort(e?.value);
      }}
      value={{
        label: sort,
        value: sort,
      }}
      className={"py-0 search-select"}
      fullwidth={true}
      options={options}
      isClearable={false}
      isSearchable={false}
    />
  );
}

export default Filter;
