import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "../../controller/auth/auth-controller";
import "./reset-password.scss";
import { validationSchema } from "./validationSchema";

const sortOptions = [
  {
    label: "What is your mothers maiden name?",
    value: "What is your mothers maiden name",
  },
  {
    label: "What is the name of your first pet?",
    value: "What is the name of your first pet",
  },
  {
    label: "What was your first car?",
    value: "What was your first car",
  },
  {
    label: "What elementary school did you attend?",
    value: "What elementary school did you attend",
  },
  {
    label: "What is the name of the town where you were born?",
    value: "What is the name of the town where you were born",
  },
  {
    label: "What was your first company Name?",
    value: "What was your first company Name",
  },
  {
    label: "My Born City?",
    value: "My Born City",
  },
];

const ResetPassword = () => {
  const authController = useAuth();
  const navigate = useNavigate();
  const { succMsg, errMsg, resetPassword } = authController;

  const formik = useFormik({
    initialValues: {
      username: "",
      newPassword: "",
      confPassword: "",
      securityQuestion: "",
      securityAnswer: "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      delete values.confPassword;
      resetPassword(values);
    },
  });

  useEffect(() => {
    if (succMsg) {
      toast.success(succMsg);
      formik.resetForm();
      navigate("/login");
    }
  }, [succMsg]);

  useEffect(() => {
    if (errMsg) {
      toast.error(errMsg);
    }
  }, [errMsg]);

  const handleCancel = () => {
    navigate("/login");
  };

  return (
    <div className="respass-container h-screen overflow-auto	bg-center bg-no-repeat bg-cover">
      <div className="h-screen justify-center items-center">
        <div className="flex justify-center items-center h-full ">
          <div className="wrapper flex flex-col	rounded px-10 py-5">
            <span>
              {" "}
              If you need to reset your password, please contact FMI Ltd. at
              &nbsp;
              <a className="forgotPass-link" href="mailto:it@freemansgroup.com">
                it@freemansgroup.com.
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

{
  /* <img
src={Logo}
style={{ width: 200 }}
alt="logo-icon"
className="m-auto"
/>
<p className="title text-lg font-normal m-auto mt-2">
Reset Your Password
</p>
<form id="loginForm" onSubmit={formik.handleSubmit}>
<div className="formWrapper my-4">
  <p className="subtitle forgotPass font-normal text-xs mb-4">
    <a href="#"> To reset your password, enter your username.</a>
  </p>
  <FormInput
    label="username"
    dom={{
      type: "text",
      name: "username",
      onChange: (e) => {
        formik.setFieldValue("username", e.target.value, true);
      },
      value: formik.values.username,
      placeholder: "username",
      className: "p-3",
    }}
    required={true}
    error={
      formik.touched.username && Boolean(formik.errors.username)
    }
    errorMessage={
      formik.touched.username && formik.errors.username
    }
    wrapperClass="mb-3"
    fullwidth={true}
  />
  <FormInput
    label="Password"
    dom={{
      type: "password",
      name: "newPassword",
      onChange: (e) => {
        formik.setFieldValue("newPassword", e.target.value, true);
      },
      value: formik.values.newPassword,
      placeholder: "password",
      className: "p-3",
    }}
    required={true}
    error={
      formik.touched.newPassword &&
      Boolean(formik.errors.newPassword)
    }
    errorMessage={
      formik.touched.newPassword && formik.errors.newPassword
    }
    wrapperClass="mb-3"
    fullwidth={true}
  />
  <FormInput
    label="Confirm Password"
    dom={{
      type: "password",
      name: "confPassword",
      onChange: (e) => {
        formik.setFieldValue(
          "confPassword",
          e.target.value,
          true
        );
      },
      value: formik.values.confPassword,
      placeholder: "confirm password",
      className: "p-3",
    }}
    required={true}
    error={
      formik.touched.confPassword &&
      Boolean(formik.errors.confPassword)
    }
    errorMessage={
      formik.touched.confPassword && formik.errors.confPassword
    }
    wrapperClass="mb-3"
    fullwidth={true}
  />
  <FormSelect
    className={"py-0"}
    label="Security Question"
    name="securityQuestion"
    onChange={(e) => {
      if (e)
        formik.setFieldValue("securityQuestion", e?.value, true);
    }}
    value={{
      label: formik.values.securityQuestion,
      value: formik.values.securityQuestion,
    }}
    placeholder={"Security Question"}
    fullwidth={true}
    required={true}
    options={sortOptions}
    wrapperClass="mb-3"
    isClearable={false}
    isSearchable={false}
    error={
      formik.touched.securityQuestion &&
      Boolean(formik.errors.securityQuestion)
    }
    errorMessage={
      formik.touched.securityQuestion &&
      formik.errors.securityQuestion
    }
  />
  <FormInput
    label="Your Answer"
    dom={{
      type: "text",
      name: "securityAnswer",
      onChange: (e) => {
        formik.setFieldValue(
          "securityAnswer",
          e.target.value,
          true
        );
      },
      value: formik.values.securityAnswer,
      placeholder: "your answer",
      className: "p-3",
    }}
    required={true}
    error={
      formik.touched.securityAnswer &&
      Boolean(formik.errors.securityAnswer)
    }
    errorMessage={
      formik.touched.securityAnswer &&
      formik.errors.securityAnswer
    }
    wrapperClass="mb-3"
    fullwidth={true}
  />
</div>
<div className="btnWrapper mt-2 flex flex-row">
  <Button
    type="button"
    className="btn btn-secondary w-full py-3 text-sm"
    onClick={handleCancel}
  >
    Cancel
  </Button>
  <Button
    type="submit"
    className="btn btn-primary w-full ml-5 py-3 text-sm"
  >
    Submit
  </Button>
</div>
</form> */
}
