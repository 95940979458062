import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FiPlusSquare } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import { toast } from "react-toastify";
import OrderImg from "../../assets/orders/order.svg";
import Button from "../../components/buttton/button";
import { useDialogContext } from "../../components/dialogs/Dialog";
import FormInput from "../../components/form/FormInput";
import FormTextarea from "../../components/form/FormTextarea";
import PageHeader from "../../components/table/pageHeader/PageHeader";
import useList from "../../controller/list/list-controller";
import useOrder from "../../controller/order/order-controller";
import {
  calculateGST,
  getAccountId,
  getAccountName,
  getCustomerType,
  getOwnerId,
  numberFormat,
} from "../../utils/helper";
import "./order-create.scss";
import { validationSchema } from "./validationSchema";
import {
  orderProdDetailLoading,
  orderProdDetailData,
} from "../../utils/constants";
import FormSelect from "../../components/form/FormSelect";

function OrderCreate() {
  const {
    succMsg,
    errMsg,
    isLoading,
    orderProdDetail,
    createdOrderData,
    createOrder,
    getOrderProductDetails,
  } = useOrder();
  const { dispatch } = useDialogContext();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [gTotal, setGTotal] = useState(0);
  const { getConstants, allConst } = useList();
  const formik = useFormik({
    initialValues: {
      accountName: getAccountName(),
      accountId: getAccountId(),
      buyerPODate: moment().format("YYYY-MM-DD"),
      buyerPONumber: "",
      specialDiscount: "",
      description: "",
      orderLineItem: [],
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const totalQty = data.reduce(
        (acc, curr) => acc + Number(curr.quantity),
        0
      );

      const values1 = {
        OrderDetails: {
          account: {
            id: values.accountId,
            name: values.accountName,
          },
          status: allConst?.status || "Order Placed",
          currencyCode: allConst?.currencyCode || "INR",
          type: allConst?.type || "Dealer",
          customerType: getCustomerType(),
          ownerId: getOwnerId(),
          sendToBusyStatus: "Pending",
          orderStartDate: moment().format("YYYY-MM-DD"),
          buyerPODate: values.buyerPODate,
          buyerPONumber: values.buyerPONumber,
          description: values.description,
          billingAddress: {
            street: values.billingStreet,
            city: values.billingCity,
            state: values.billingState,
            postalCode: values.billingPostalCode,
            country: values.billingCountry,
          },
          shippingAddress: {
            street: values.shippingStreet,
            city: values.shippingCity,
            state: values.shippingState,
            postalCode: values.shippingPostalCode,
            country: values.shippingCountry,
          },
          isPortalOrder: "Yes",
          specialDiscountId: null,
          specialDiscount: values.specialDiscount,
          totalMOQ: totalQty,
          orderLines: data
            ?.filter((orderI, index) => {
              return orderI?.productId;
            })
            ?.map((data) => {
              const { tempQuantity, ...orderData } = data;
              return {
                ...orderData,
                product: {
                  id: orderData.productId,
                  name: orderData.productName,
                },
                currencyCode: orderData.currency,
                priceAfterDiscount: orderData.discountPrice,
                offerAndDiscountId: null,
              };
            }),
        },
      };

      createOrder(values1, false);
    },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "id",
        Cell: ({ value: initialValue, row: { index }, column: { id } }) => (
          <p>{index + 1}</p>
        ),
      },
      {
        Header: "Product Name",
        accessor: "productName",
        Cell: ({
          value: initialValue,
          row: { index },
          column: { id },
          data,
        }) => {
          const handleAddName = (values) => {
            // const additionalDiscount = allConst?.giveExtraDiscountForPortalOrder
            //   ? 0.5
            //   : 0;
            const additionalDiscount = 0;
            const temp = [...data];
            const t1 = values.unitPrice;

            const t3 = t1 * (values?.discount / 100 + additionalDiscount / 100);
            const unitPriced = t1 - t3;
            const totPri = unitPriced * 1;
            const gst = calculateGST(totPri * values.gst);
            temp[index] = {
              ...temp[index],
              ...values,
              unitPrice: t1,
              unitPriced: unitPriced,
              quantity: 1,
              tempQuantity: 1,
              discountPrice: Math.round(unitPriced * 1000) / 1000,
              totalPrice: Math.round((totPri + gst) * 1000) / 1000,
              additionalDiscount: additionalDiscount,
            };

            setData(temp);
          };

          return (
            <div>
              <FormInput
                dom={{
                  type: "text",
                  name: "productName",
                  value: data[index].productName,
                  className: "p-2",
                  onClick: () => {
                    dispatch({
                      type: "openDialog",
                      payload: {
                        data: {
                          handleAddName: handleAddName,
                          orderProdDetail,
                          isLoading,
                          footer: true,
                          productList: data || [],
                          cancelBtn: true,
                          isSearch: true,
                          title: "Product Name",
                          buttonText: {
                            submit: "Save",
                          },
                          className: "px-0 py-0",
                        },
                        path: `orders/ProductList`,
                      },
                    });
                  },
                }}
                required={true}
                fullwidth={true}
              />
            </div>
          );
        },
      },
      { Header: "Product Code", accessor: "productCode" },
      { Header: "Unit Price", accessor: "unitPrice" },
      {
        Header: "Product Quantity",
        accessor: "quantity",
        Cell: ({
          value: initialValue,
          row: { index },
          column: { id },
          data,
        }) => {
          return (
            <div>
              <FormInput
                dom={{
                  type: "number",
                  name: "quantity",
                  className: "p-2",
                  disabled: !data[index].productName,
                  value: data[index].tempQuantity,
                  onFocus: (e) => {
                    const temp = [...data];
                    temp[index].tempQuantity = "";
                    setData(temp);
                  },
                  onChange: (e) => {
                    let q = e.target.value;
                    const temp = [...data];
                    temp[index].quantity = q;
                    temp[index].tempQuantity = q;
                    const totPri = temp[index]?.["unitPriced"] * (q || 0);
                    const gst = calculateGST(totPri * temp[index].gst);
                    temp[index].totalPrice =
                      Math.round((totPri + gst) * 1000) / 1000;
                    setData(temp);
                  },
                  onBlur: (e) => {
                    const temp = [...data];
                    let q = e.target.value || temp[index].quantity;

                    if (!q || q == 0) {
                      const totPri = temp[index]?.["unitPriced"] * 1;
                      const gst = calculateGST(totPri * temp[index].gst);
                      temp[index] = {
                        ...temp[index],
                        quantity: Number(1),
                        tempQuantity: Number(1),
                        totalPrice: Math.round((totPri + gst) * 1000) / 1000,
                      };
                      setData(temp);
                    } else {
                      temp[index].tempQuantity = temp[index].quantity;
                      setData(temp);
                    }
                  },
                }}
                required={true}
                fullwidth={true}
              />
            </div>
          );
        },
      },
      {
        Header: "Discount",
        accessor: "discount",
        Cell: (row) => {
          return `${row?.value}%`;
        },
      },
      // {
      //   Header: "Additional Discount",
      //   accessor: "additionalDiscount",
      //   Cell: (row) => {
      //     return `${
      //       row?.row?.original?.additionalDiscount
      //         ? row?.row?.original?.additionalDiscount?.toFixed(2)
      //         : 0.0
      //     }%`;
      //   },
      // },
      {
        Header: "Price After Discount",
        accessor: "discountPrice",
      },
      {
        Header: "GST",
        accessor: "gst",
        Cell: (row) => {
          return `${row?.value}%`;
        },
      },
      {
        Header: "Total Price",
        accessor: "totalPrice",
        Cell: (row) => {
          return `${numberFormat(row?.value, allConst?.currencyCode)}`;
        },
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({
          value: initialValue,
          row: { index },
          column: { id },
          data,
        }) => {
          return (
            <div className="flex flex-row justify-end">
              <div
                className="p-2"
                style={{
                  width: "fit-content",
                  color: "#A31400",
                  fontSize: 18,
                }}
                onClick={() => {
                  const tempArr = [...data];
                  tempArr.splice(index, 1);
                  setData(tempArr);
                }}
              >
                <RiDeleteBin6Line />
              </div>
            </div>
          );
        },
      },
    ],
    [allConst, isLoading, orderProdDetail]
  );

  useEffect(() => {
    orderProdDetailLoading.next(isLoading);
  }, [isLoading]);

  useEffect(() => {
    orderProdDetailData.next(orderProdDetail);
  }, [orderProdDetail]);

  useEffect(() => {
    if (allConst) {
      formik.setFieldValue(
        "isBuyerPONumberMandatory",
        allConst?.isBuyerPONumberMandatory
      );
      formik.setFieldValue("billingStreet", allConst?.billingStreet);
      formik.setFieldValue("billingCity", allConst?.billingCity);
      formik.setFieldValue("billingState", allConst?.billingState);
      formik.setFieldValue("billingPostalCode", allConst?.billingPostalCode);
      formik.setFieldValue("billingCountry", allConst?.billingCountry);
      formik.setFieldValue("shippingStreet", allConst?.shippingStreet);
      formik.setFieldValue("shippingCity", allConst?.shippingCity);
      formik.setFieldValue("shippingState", allConst?.shippingState);
      formik.setFieldValue("shippingPostalCode", allConst?.shippingPostalCode);
      formik.setFieldValue("shippingCountry", allConst?.shippingCountry);
    }
  }, [allConst]);

  useEffect(() => {
    if (data.length > 0) {
      const sum = data.reduce(
        (prev, current) => parseFloat(prev) + parseFloat(current.totalPrice),
        0
      );
      setGTotal(Math.round(sum * 1000) / 1000);
    } else {
      setGTotal(0);
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(createdOrderData).length > 0) {
      dispatch({
        type: "openDialog",
        payload: {
          data: {
            createOrder: createOrder,
            values: createdOrderData,
            gTotal: gTotal,
            footer: false,
            cancelBtn: true,
            title: "Order Preview",
            onSubmit: true,
            className: "pb-0",
          },
          path: `OrderPreview`,
        },
      });
    }
  }, [createdOrderData]);

  useEffect(() => {
    getConstants({
      objectName: "Order",
    });
    getOrderProductDetails({
      objectName: "Order Product",
      pageNo: 1,
      orderByField: "",
      orderByAPIField: "",
      orderDirection: "",
      searchKey: "",
    });
  }, []);

  useEffect(() => {
    if (succMsg) {
      toast.success(succMsg);
      formik.resetForm();
      navigate("/orders");
    }
  }, [succMsg]);

  useEffect(() => {
    if (errMsg) {
      toast.error(errMsg);
    }
  }, [errMsg]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
      },
      useFilters,
      useGlobalFilter,
      useSortBy
    );

  return allConst ? (
    <>
      <PageHeader
        img={OrderImg}
        text="New Order"
        isSubImg={true}
        isBackBtn={true}
        onBackClick={() => navigate("/orders")}
      />
      <div className="order-create-container p-4 rounded-lg">
        <form id="createOrderForm" onSubmit={formik.handleSubmit}>
          <div className="title py-3.5 px-4 rounded-lg font-semibold text-base">
            Information
          </div>
          <div className="py-6 ">
            <div className="grid grid-cols-3 mb-5 gap-x-7">
              <div>
                <FormInput
                  label="Account Name"
                  dom={{
                    type: "text",
                    name: "accountName",
                    className: "pr-9 bg-gray-100",
                    readOnly: true,
                    disabled: true,
                    value: formik?.values?.accountName,
                    onChange: () => {},
                  }}
                  fullwidth={true}
                  error={
                    formik.touched.accountName &&
                    Boolean(formik.errors.accountName)
                  }
                  errorMessage={
                    formik.touched.accountName && formik.errors.accountName
                  }
                />
              </div>
              <div className="">
                <FormInput
                  label="Buyer PO Date"
                  dom={{
                    name: "buyerPODate",
                    type: "date",
                    readOnly: true,
                    disabled: true,
                    className: "p-2.5 bg-gray-100",
                    value: formik?.values?.buyerPODate,
                    onChange: (e) => {
                      formik.setFieldValue("buyerPODate", e.target.value, true);
                    },
                  }}
                  required={true}
                  fullwidth={true}
                  error={
                    formik.touched.buyerPODate &&
                    Boolean(formik.errors.buyerPODate)
                  }
                  errorMessage={
                    formik.touched.buyerPODate && formik.errors.buyerPODate
                  }
                />
              </div>

              <div>
                <FormInput
                  label="Buyer PO Number"
                  dom={{
                    name: "buyerPONumber",
                    type: "text",
                    value: formik?.values?.buyerPONumber,
                    onChange: (e) =>
                      formik.setFieldValue(
                        "buyerPONumber",
                        e.target.value,
                        true
                      ),
                  }}
                  required={
                    formik?.values?.isBuyerPONumberMandatory ? true : false
                  }
                  fullwidth={true}
                  error={
                    formik.touched.buyerPONumber &&
                    Boolean(formik.errors.buyerPONumber)
                  }
                  errorMessage={
                    formik.touched.buyerPONumber && formik.errors.buyerPONumber
                  }
                />
              </div>

              <div className="mt-3">
                <FormSelect
                  label="Special Discount"
                  className={"py-0 search-select"}
                  options={allConst?.specialDiscountOptions || []}
                  isClearable={false}
                  isSearchable={false}
                  name="specialDiscount"
                  fullwidth={true}
                  onChange={(value) => {
                    formik.setFieldValue("specialDiscount", value?.value);
                  }}
                  value={{
                    label: formik?.values?.specialDiscount,
                    value: formik?.values?.specialDiscount,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="title py-3.5 px-4 rounded-lg font-semibold text-base mb-4">
            Product Details
          </div>
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {headerGroup.headers.map((column, i) => (
                    <th
                      {...column.getHeaderProps(
                        column?.id !== "action" && column.getSortByToggleProps()
                      )}
                      className={`${
                        column.isSorted
                          ? "flex flex-row justify-between items-center"
                          : ""
                      } p-3.5	${i === 0 ? "rounded-tl-lg" : ""} ${
                        i === headerGroup.headers.length - 1
                          ? "rounded-tr-lg"
                          : ""
                      }`}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <div className="accIconClose inline-block mr-3 rotate-45 ml-2" />
                          ) : (
                            <div className="accIconOpen inline-block mr-3 ml-2" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={i} className="cursor-pointer">
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="p-3.5">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {rows?.length < 1 ? (
                <p className="mt-2 font-medium text-sm no-table-record">
                  No Record Found
                </p>
              ) : null}
            </tbody>
          </table>

          <div className="title py-3.5 px-4 rounded-lg font-semibold text-base mt-4">
            <div className="flex flex-row justify-between items-center">
              <div />
              <div
                className="cursor-pointer"
                onClick={() =>
                  setData((prev) => [
                    ...prev,
                    {
                      productName: "",
                      productId: "",
                      productCode: "",
                      unitPrice: 0,
                      quantity: 0,
                      tempQuantity: "",
                      discount: 0.0,
                      additionalDiscount: 0.0,
                      discountPrice: 0.0,
                      gst: 0.0,
                      totalPrice: 0.0,
                    },
                  ])
                }
              >
                <FiPlusSquare style={{ fontSize: 30, color: "#00A328" }} />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-end m-2">
            <p className="mr-4 font-normal text-sm  gtotal">Grand Total</p>
            <p className="font-medium text-sm gtotalval">
              {numberFormat(gTotal, allConst?.currencyCode)}
            </p>
          </div>

          <div className="py-6 pt-0">
            <div>
              <FormTextarea
                label="Description"
                dom={{
                  name: "description",
                  rows: 4,
                  value: formik?.values?.description,
                  onChange: (e) =>
                    formik.setFieldValue("description", e.target.value, true),
                }}
                // required={true}
                fullwidth={true}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                errorMessage={
                  formik.touched.description && formik.errors.description
                }
              />
            </div>
          </div>
          <div className="title py-3.5 px-4 rounded-lg font-semibold text-base">
            Address Details
          </div>
          <div className="py-6">
            <div className="grid  gap-7 grid-cols-2">
              <div className="">
                <div>
                  <FormInput
                    label="Billing Street"
                    dom={{
                      name: "billingStreet",
                      type: "text",
                      value: formik?.values?.billingStreet,
                      readOnly: true,
                      disabled: true,
                      onChange: () => {},
                      className: "bg-gray-100 mb-3",
                    }}
                    required={true}
                    fullwidth={true}
                    error={
                      formik.touched.billingStreet &&
                      Boolean(formik.errors.billingStreet)
                    }
                    errorMessage={
                      formik.touched.billingStreet &&
                      formik.errors.billingStreet
                    }
                  />
                </div>
                <div>
                  <FormInput
                    label="Billing City"
                    dom={{
                      name: "billingCity",
                      type: "text",
                      value: formik?.values?.billingCity,
                      readOnly: true,
                      disabled: true,
                      onChange: () => {},
                      className: "bg-gray-100 mb-3",
                    }}
                    required={true}
                    fullwidth={true}
                    error={
                      formik.touched.billingCity &&
                      Boolean(formik.errors.billingCity)
                    }
                    errorMessage={
                      formik.touched.billingCity && formik.errors.billingCity
                    }
                  />
                </div>
                <div>
                  <FormInput
                    label="Billing State"
                    dom={{
                      name: "billingState",
                      type: "text",
                      value: formik?.values?.billingState,
                      readOnly: true,
                      disabled: true,
                      onChange: () => {},
                      className: "bg-gray-100 mb-3",
                    }}
                    required={true}
                    fullwidth={true}
                    error={
                      formik.touched.billingState &&
                      Boolean(formik.errors.billingState)
                    }
                    errorMessage={
                      formik.touched.billingState && formik.errors.billingState
                    }
                  />
                </div>
                <div>
                  <FormInput
                    label="Billing Postal Code"
                    dom={{
                      name: "billingPostalCode",
                      type: "text",
                      value: formik?.values?.billingPostalCode,
                      readOnly: true,
                      disabled: true,
                      onChange: () => {},
                      className: "bg-gray-100 mb-3",
                    }}
                    required={true}
                    fullwidth={true}
                    error={
                      formik.touched.billingPostalCode &&
                      Boolean(formik.errors.billingPostalCode)
                    }
                    errorMessage={
                      formik.touched.billingPostalCode &&
                      formik.errors.billingPostalCode
                    }
                  />
                </div>
                <div>
                  <FormInput
                    label="Billing Country"
                    dom={{
                      name: "billingCountry",
                      type: "text",
                      value: formik?.values?.billingCountry,
                      readOnly: true,
                      disabled: true,
                      onChange: () => {},
                      className: "bg-gray-100 mb-3",
                    }}
                    required={true}
                    fullwidth={true}
                    error={
                      formik.touched.billingCountry &&
                      Boolean(formik.errors.billingCountry)
                    }
                    errorMessage={
                      formik.touched.billingCountry &&
                      formik.errors.billingCountry
                    }
                  />
                </div>
              </div>{" "}
              <div className="">
                <div>
                  <FormInput
                    label="Shipping Street"
                    dom={{
                      name: "shippingStreet",
                      type: "text",
                      value: formik?.values?.shippingStreet,
                      className: "mb-3",
                      onChange: (e) =>
                        formik.setFieldValue(
                          "shippingStreet",
                          e.target.value,
                          true
                        ),
                    }}
                    required={true}
                    fullwidth={true}
                    error={
                      formik.touched.shippingStreet &&
                      Boolean(formik.errors.shippingStreet)
                    }
                    errorMessage={
                      formik.touched.shippingStreet &&
                      formik.errors.shippingStreet
                    }
                  />
                </div>
                <div>
                  <FormInput
                    label="Shipping City"
                    dom={{
                      name: "shippingCity",
                      type: "text",
                      className: "mb-3",
                      value: formik?.values?.shippingCity,
                      onChange: (e) =>
                        formik.setFieldValue(
                          "shippingCity",
                          e.target.value,
                          true
                        ),
                    }}
                    required={true}
                    fullwidth={true}
                    error={
                      formik.touched.shippingCity &&
                      Boolean(formik.errors.shippingCity)
                    }
                    errorMessage={
                      formik.touched.shippingCity && formik.errors.shippingCity
                    }
                  />
                </div>
                <div>
                  <FormInput
                    label="Shipping State"
                    dom={{
                      name: "shippingState",
                      type: "text",
                      className: "mb-3",
                      value: formik?.values?.shippingState,
                      onChange: (e) =>
                        formik.setFieldValue(
                          "shippingState",
                          e.target.value,
                          true
                        ),
                    }}
                    required={true}
                    fullwidth={true}
                    error={
                      formik.touched.shippingState &&
                      Boolean(formik.errors.shippingState)
                    }
                    errorMessage={
                      formik.touched.shippingState &&
                      formik.errors.shippingState
                    }
                  />
                </div>
                <div>
                  <FormInput
                    label="Shipping Postal Code"
                    dom={{
                      name: "shippingPostalCode",
                      type: "text",
                      className: "mb-3",
                      value: formik?.values?.shippingPostalCode,
                      onChange: (e) =>
                        formik.setFieldValue(
                          "shippingPostalCode",
                          e.target.value,
                          true
                        ),
                    }}
                    required={true}
                    fullwidth={true}
                    error={
                      formik.touched.shippingPostalCode &&
                      Boolean(formik.errors.shippingPostalCode)
                    }
                    errorMessage={
                      formik.touched.shippingPostalCode &&
                      formik.errors.shippingPostalCode
                    }
                  />
                </div>
                <div>
                  <FormInput
                    label="Shipping Country"
                    dom={{
                      name: "shippingCountry",
                      type: "text",
                      className: "mb-3",
                      value: formik?.values?.shippingCountry,
                      onChange: (e) =>
                        formik.setFieldValue(
                          "shippingCountry",
                          e.target.value,
                          true
                        ),
                    }}
                    required={true}
                    fullwidth={true}
                    error={
                      formik.touched.shippingCountry &&
                      Boolean(formik.errors.shippingCountry)
                    }
                    errorMessage={
                      formik.touched.shippingCountry &&
                      formik.errors.shippingCountry
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-end mt-6">
            <button
              type="button"
              className="btn btn-primary-outlined mx-1.5 capitalize"
              onClick={() => navigate("/orders")}
              disabled={isLoading}
            >
              Cancel
            </button>
            <Button
              type="submit"
              className="btn btn-primary disabled:opacity-70 w-10 ml-1.5 capitalize"
              disabled={
                isLoading ||
                data?.filter((orderI, index) => {
                  return orderI?.productId;
                }).length === 0
              }
              loader={isLoading}
            >
              Preview
            </Button>
          </div>
        </form>
      </div>
    </>
  ) : (
    ""
  );
}

export default OrderCreate;
