import * as yup from "yup";

export const validationSchema = yup.object({
  accountName: yup
    .string("Enter account name")
    .required("AccountName is required"),

  buyerPODate: yup
    .string("Enter buyer PO date")
    .required("Buyer PO date is required"),
  buyerPONumber: yup.string().when("isBuyerPONumberMandatory", {
    is: true,
    then: yup.string().required("Buyer PO number is required"),
  }),
  // description: yup
  //   .string("Enter description")
  //   .required("Description is required"),
  billingStreet: yup
    .string("Enter billing street")
    .required("Billing street is required"),
  billingCity: yup
    .string("Enter billing city")
    .required("Billing city is required"),
  billingState: yup
    .string("Enter billing state")
    .required("Billing state is required"),
  billingPostalCode: yup
    .string("Enter billing postal code")
    .required("Billing postal code is required"),
  billingCountry: yup
    .string("Enter billing country")
    .required("Billing country is required"),
  shippingStreet: yup
    .string("Enter shipping street")
    .required("Shipping street is required"),
  shippingCity: yup
    .string("Enter shipping city")
    .required("Shipping city is required"),
  shippingState: yup
    .string("Enter shipping state")
    .required("Shipping state is required"),
  shippingPostalCode: yup
    .string("Enter shipping postal code")
    .required("Shipping postal code is required"),
  shippingCountry: yup
    .string("Enter shipping country")
    .required("Shipping country is required"),
});

// .when('showEmail', {
//   is: true,
//   then: yup.string().required('Must enter email address'),
// }),
