import { useState } from "react";
import axiosInstance from "../../utils/AxiosAPI";

const useProduct = () => {
  const [prodDetail, setProdDetail] = useState({});
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getProductDetails = async (id) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);

    try {
      const response = await axiosInstance.post(`GetProductDetail/`, {
        productId: id,
      });
      if (response?.data?.data) {
        setSuccMsg(response?.data?.message);
        setProdDetail(response?.data?.data);
      } else {
        setErrMsg(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  return {
    prodDetail,
    succMsg,
    errMsg,
    isLoading,
    getProductDetails,
  };
};

export default useProduct;
