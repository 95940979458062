import React from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router";
import Header from "../../components/sharedLayout/Header";
import Sidebar from "../../components/sharedLayout/Sidebar";
import "./sharedLayout.scss";

const SharedLayout = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div>
      <Header />
      <Sidebar pathname={pathname} />
      <div
        id="content"
        className="overflow-auto absolute top-0	right-0	overflow-x-hidden"
      >
        <div className="contentDiv h-full w-full overflow-auto p-3">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SharedLayout;
