import React from "react";

export default function Spinner({ color = "white" }) {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 0C11.6967 0 11.0455 0.651222 11.0455 1.45455V5.09091C11.0455 5.89423 11.6967 6.54545 12.5 6.54545C13.3033 6.54545 13.9546 5.89423 13.9546 5.09091V1.45455C13.9546 0.651222 13.3033 0 12.5 0ZM20.9853 3.51484C20.4172 2.9468 19.4963 2.9468 18.9282 3.51484L16.3569 6.08614C15.7889 6.65417 15.7889 7.57514 16.3569 8.14317C16.925 8.71121 17.8459 8.71121 18.414 8.14317L20.9853 5.57188C21.5533 5.00384 21.5533 4.08287 20.9853 3.51484ZM23.0455 10.5455C23.8488 10.5455 24.5 11.1967 24.5 12C24.5 12.8033 23.8488 13.4546 23.0455 13.4546H19.4091C18.6058 13.4546 17.9545 12.8033 17.9545 12C17.9545 11.1967 18.6058 10.5455 19.4091 10.5455H23.0455ZM20.9852 20.4853C21.5532 19.9172 21.5532 18.9963 20.9852 18.4282L18.4139 15.8569C17.8458 15.2889 16.9249 15.2889 16.3568 15.8569C15.7888 16.425 15.7888 17.3459 16.3568 17.914L18.9281 20.4853C19.4962 21.0533 20.4171 21.0533 20.9852 20.4853ZM13.9545 22.5455C13.9545 23.3488 13.3033 24 12.5 24C11.6967 24 11.0454 23.3488 11.0454 22.5455V18.9091C11.0454 18.1058 11.6967 17.4545 12.5 17.4545C13.3033 17.4545 13.9545 18.1058 13.9545 18.9091V22.5455ZM4.01473 20.4852C4.58276 21.0532 5.50373 21.0532 6.07176 20.4852L8.64306 17.9139C9.2111 17.3458 9.2111 16.4249 8.64306 15.8568C8.07503 15.2888 7.15406 15.2888 6.58603 15.8568L4.01473 18.4281C3.44669 18.9962 3.44669 19.9171 4.01473 20.4852ZM1.95455 13.4545C1.15122 13.4545 0.5 12.8033 0.5 12C0.5 11.1967 1.15122 10.5454 1.95455 10.5454H5.59091C6.39423 10.5454 7.04545 11.1967 7.04545 12C7.04545 12.8033 6.39423 13.4545 5.59091 13.4545H1.95455ZM4.01484 3.51473C3.4468 4.08276 3.4468 5.00373 4.01484 5.57176L6.58614 8.14306C7.15417 8.7111 8.07514 8.7111 8.64317 8.14306C9.21121 7.57503 9.21121 6.65406 8.64317 6.08602L6.07188 3.51473C5.50384 2.94669 4.58287 2.94669 4.01484 3.51473Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0.5"
          y1={0}
          x2="0.5"
          y2={24}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} stopOpacity="0.01" />
          <stop offset={1} stopColor={color} />
        </linearGradient>
      </defs>
    </svg>
  );
}
