import { useState } from "react";
import axiosInstance from "../../utils/AxiosAPI";
import { getCookie } from "../../utils/session";

const useList = () => {
  const [listData, setListData] = useState({});
  const [tableData, setTableData] = useState({});
  const [productsData, setProductsData] = useState({});
  const [allConst, setAllConst] = useState({});
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [faqData, setFaqData] = useState([]);

  const getListViews = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    const accountId = getCookie("accountId");

    try {
      const response = await axiosInstance.post(`GetListViews/`, {
        accountId,
        ...data,
      });
      if (response?.data?.data) {
        setSuccMsg(response?.data?.message);
        setListData(response?.data?.data);
      } else {
        setErrMsg(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  const getListViewRecords = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    const accountId = getCookie("accountId");

    try {
      const response = await axiosInstance.post(`GetListViewRecords/`, {
        accountId,
        ...data,
      });
      if (response?.data?.data) {
        setSuccMsg(response?.data?.message);
        setTableData(response?.data?.data);
      } else {
        setErrMsg(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  const getProductRecords = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    const accountId = getCookie("accountId");

    try {
      const response = await axiosInstance.post(`GetProductRecords/`, {
        accountId,
        ...data,
      });
      if (response?.data?.data) {
        setSuccMsg(response?.data?.message);
        setProductsData(response?.data?.data);
      } else {
        setErrMsg(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  const getConstants = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    const accountId = getCookie("accountId");

    try {
      const response = await axiosInstance.post(`GetConstants/`, {
        accountId,
        ...data,
      });
      if (response?.data?.data) {
        setSuccMsg(response?.data?.message);
        setAllConst(response?.data?.data);
      } else {
        setErrMsg(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  const sendContactUs = async (data) => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    const accountId = getCookie("accountId");

    try {
      const response = await axiosInstance.post(`ContactUs/`, {
        accountId,
        ...data,
      });
      if (response?.data?.status) {
        setSuccMsg(response?.data?.message);
      } else {
        setErrMsg(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  const faqList = async () => {
    setSuccMsg("");
    setErrMsg("");
    setIsLoading(true);
    const accountId = getCookie("accountId");

    try {
      const response = await axiosInstance.post(`FAQ/`, {
        accountId,
      });
      if (response?.data?.status) {
        setSuccMsg(response?.data?.message);
        setFaqData(response?.data?.data);
      } else {
        setErrMsg(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error--", error);
      setErrMsg(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  return {
    listData,
    tableData,
    productsData,
    allConst,
    succMsg,
    errMsg,
    isLoading,
    faqData,
    getListViews,
    getListViewRecords,
    getProductRecords,
    getConstants,
    sendContactUs,
    faqList,
  };
};

export default useList;
