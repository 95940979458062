import React, { useState, useRef, useEffect } from "react";
import "./product-details.scss";
import ProdImg from "../../assets/products/product.svg";
import InfoImg from "../../assets/accounts/info.svg";
import ProdImgIcon from "../../assets/products/prodImg.svg";
import NoProdImgIcon from "../../assets/products/noProdImg.svg";
import VideoIcon from "../../assets/products/video.svg";
import AdditionalImg from "../../assets/fmi-cases/additional.svg";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import Slick from "react-slick";
import PrevImg from "../../assets/prev-icon.js";
import PageHeader from "../../components/table/pageHeader/PageHeader";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { useParams, useNavigate } from "react-router-dom";
import useProduct from "../../controller/product/product-controller";
import ModalVideo from "react-modal-video";
import YouTube from "react-youtube";
import { AiOutlineEye } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import Lightbox from "react-image-lightbox";

const ProductDetails = () => {
  const sliderRef = useRef();
  const sliderRef3 = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlide3, setCurrentSlide3] = useState(0);
  const [search, setSearch] = useState();
  const [openAcc1, setOpenAcc1] = useState(["0"]);
  const [openAcc2, setOpenAcc2] = useState(["1"]);
  const [openAcc3, setOpenAcc3] = useState(["2"]);
  const [openAcc4, setOpenAcc4] = useState(["3"]);
  const [isLoader, setIsLoader] = useState(false);
  const [prodData, setProdData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [videoId, setVideoId] = useState(null);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpenImgLightBox, setIsOpenImgLightBox] = useState(false);
  const [isOpenDocLightBox, setIsOpenDocLightBox] = useState(false);
  const [docLightBoxUrl, setDocLightBoxUrl] = useState("");
  const rx =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const { getProductDetails, prodDetail, isLoading, errMsg } = useProduct();

  const setting = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    arrows: false,
    afterChange: (current) => setCurrentSlide(current),
  };

  const setting3 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    arrows: false,
    afterChange: (current) => setCurrentSlide3(current),
  };

  useEffect(() => {
    getProductDetails(params?.pid);
  }, []);

  useEffect(() => {
    setProdData({
      ...prodDetail,
      productYouTubeURLs: prodDetail?.productYouTubeURLs?.map((prodUrl) => {
        return prodUrl?.url;
      }),
    });
  }, [prodDetail]);

  useEffect(() => {
    setIsLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (errMsg) {
      toast.error(errMsg);
    }
  }, [errMsg]);

  const videoArr = prodData?.productYouTubeURLs;

  const opts = {
    height: "210px",
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div className="product-details-container h-full flex flex-col">
      {isLoader ? (
        <Loader />
      ) : (
        <>
          {videoId && (
            <ModalVideo
              channel="youtube"
              isOpen={openModal}
              videoId={videoId}
              onClose={() => {
                setOpenModal(false);
                setVideoId(null);
              }}
            />
          )}
          {isOpenImgLightBox && (
            <Lightbox
              mainSrc={prodDetail?.productImage[photoIndex]}
              nextSrc={
                prodDetail?.productImage[
                  (photoIndex + 1) % prodDetail?.productImage.length
                ]
              }
              prevSrc={
                prodDetail?.productImage[
                  (photoIndex + prodDetail?.productImage.length - 1) %
                    prodDetail?.productImage.length
                ]
              }
              onCloseRequest={() => setIsOpenImgLightBox(false)}
              onMovePrevRequest={() =>
                setPhotoIndex(
                  (photoIndex + prodDetail?.productImage.length - 1) %
                    prodDetail?.productImage.length
                )
              }
              onMoveNextRequest={() =>
                setPhotoIndex(
                  (photoIndex + 1) % prodDetail?.productImage.length
                )
              }
            />
          )}
          {isOpenDocLightBox && docLightBoxUrl && (
            <div
              className="fixed top-0 right-0 bottom-0 left-0"
              style={{
                backgroundColor: "rgba(0,0,0,0.5)",
                zIndex: 99,
              }}
            >
              <div
                className="absolute text-white top-4 right-8 cursor-pointer text-3xl p-2.5 rounded-full"
                style={{
                  zIndex: 100,
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                }}
              >
                <RxCross2
                  onClick={() => {
                    setIsOpenDocLightBox(false);
                    setDocLightBoxUrl("");
                  }}
                />
              </div>
              <iframe
                id="iframe"
                title={docLightBoxUrl}
                src={docLightBoxUrl}
                height="100%"
                width="100%"
                style={{ background: "rgba(0, 0, 0, 0.6)" }}
              />
            </div>
          )}
          <PageHeader
            img={ProdImg}
            text="Product Details"
            subTitle={prodData?.productName}
            search={search}
            setSearch={setSearch}
            isBackBtn={true}
            onBackClick={() => navigate("/products")}
          />
          <div className="accordianWrapper flex gap-3">
            <div className="flex-1">
              <Accordion
                allowMultipleExpanded={true}
                allowZeroExpanded={true}
                preExpanded={["0"]}
                onChange={(item) => setOpenAcc1(item)}
                className="col-span-2"
              >
                <AccordionItem uuid={"0"} className="accItem mb-3">
                  <AccordionItemHeading>
                    <AccordionItemButton
                      className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                        openAcc1.includes("0") ? "rounded-t-lg" : "rounded-lg"
                      }`}
                    >
                      <div className="flex flex-row font-semibold text-sm accTitle">
                        <img src={InfoImg} alt="infoImg" className="mr-2" />
                        Basic Information
                      </div>
                      <AccordionItemState>
                        {({ expanded }) =>
                          expanded ? (
                            <div className="accIconOpen inline-block mr-3" />
                          ) : (
                            <div className="accIconClose inline-block  rotate-45" />
                          )
                        }
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="accPanel grid rounded-b-lg py-4 px-4">
                      <div className="grid grid-cols-2 mb-4">
                        <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                          <div className="fieldName font-normal text-sm ">
                            Product Name
                          </div>
                          <div className="fieldDetails font-medium text-sm">
                            {prodData?.productName}
                          </div>
                        </div>
                        <div className="grid grid-cols-2 fieldWrapper pb-2">
                          <div className="fieldName font-normal text-sm ">
                            Product Code
                          </div>
                          <div className="fieldDetails font-medium text-sm">
                            {prodData?.productCode}
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-2 mb-4">
                        <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                          <div className="fieldName font-normal text-sm ">
                            Size
                          </div>
                          <div className="fieldDetails font-medium text-sm">
                            {prodData?.size}
                          </div>
                        </div>
                        <div className="grid grid-cols-2 fieldWrapper pb-2">
                          <div className="fieldName font-normal text-sm ">
                            Unit of Measure
                          </div>
                          <div className="fieldDetails font-medium text-sm">
                            {prodData?.unitofMeasure}
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-2">
                        <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                          <div className="fieldName font-normal text-sm ">
                            Available Stock
                          </div>
                          <div className="fieldDetails font-medium text-sm">
                            {prodData?.availableStock}
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <Accordion
                allowMultipleExpanded={true}
                allowZeroExpanded={true}
                preExpanded={["1"]}
                onChange={(item) => setOpenAcc2(item)}
                className="col-span-2"
              >
                <AccordionItem uuid={"1"} className="accItem mb-3">
                  <AccordionItemHeading>
                    <AccordionItemButton
                      className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                        openAcc2.includes("1") ? "rounded-t-lg" : "rounded-lg"
                      }`}
                    >
                      <div className="flex flex-row font-semibold text-sm accTitle">
                        <img
                          src={AdditionalImg}
                          alt="additionalImg"
                          className="mr-2"
                        />
                        Tax Information
                      </div>
                      <AccordionItemState>
                        {({ expanded }) =>
                          expanded ? (
                            <div className="accIconOpen" />
                          ) : (
                            <div className="accIconClose rotate-45" />
                          )
                        }
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="accPanel grid rounded-b-lg py-4 px-4">
                      <div className="grid grid-cols-2">
                        <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                          <div className="fieldName font-normal text-sm ">
                            HSN Code
                          </div>
                          <div className="fieldDetails font-medium text-sm">
                            {prodData?.hsnCode}
                          </div>
                        </div>
                        <div className="grid grid-cols-2 fieldWrapper pb-2">
                          <div className="fieldName font-normal text-sm ">
                            Tax Category
                          </div>
                          <div className="fieldDetails font-medium text-sm">
                            {prodData?.taxCategory}
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <Accordion
                allowMultipleExpanded={true}
                allowZeroExpanded={true}
                preExpanded={["2"]}
                onChange={(item) => setOpenAcc3(item)}
                className="col-span-2"
              >
                <AccordionItem uuid={"2"} className="accItem mb-3">
                  <AccordionItemHeading>
                    <AccordionItemButton
                      className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                        openAcc3.includes("2") ? "rounded-t-lg" : "rounded-lg"
                      }`}
                    >
                      <div className="flex flex-row font-semibold text-sm accTitle">
                        <img
                          src={AdditionalImg}
                          alt="additionalImg"
                          className="mr-2"
                        />
                        Shipping Information
                      </div>
                      <AccordionItemState>
                        {({ expanded }) =>
                          expanded ? (
                            <div className="accIconOpen" />
                          ) : (
                            <div className="accIconClose rotate-45" />
                          )
                        }
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="accPanel grid rounded-b-lg py-4 px-4">
                      <div className="grid grid-cols-2 mb-4">
                        <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                          <div className="fieldName font-normal text-sm ">
                            Fulfilled From
                          </div>
                          <div className="fieldDetails font-medium text-sm">
                            {prodData?.fulfilledFrom}
                          </div>
                        </div>
                        <div className="grid grid-cols-2 fieldWrapper pb-2">
                          <div className="fieldName font-normal text-sm ">
                            Packing Type
                          </div>
                          <div className="fieldDetails font-medium text-sm">
                            {prodData?.packingType}
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-2">
                        <div className="grid grid-cols-2 fieldWrapper pb-2 mr-10">
                          <div className="fieldName font-normal text-sm ">
                            Packing Quantity
                          </div>
                          <div className="fieldDetails font-medium text-sm">
                            {prodData?.packingQuantity}
                          </div>
                        </div>
                        <div className="grid grid-cols-2 fieldWrapper pb-2">
                          <div className="fieldName font-normal text-sm ">
                            Packing Weight
                          </div>
                          <div className="fieldDetails font-medium text-sm">
                            {prodData?.packingWeight}
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              {prodData?.productEBC && (
                <Accordion
                  allowMultipleExpanded={true}
                  allowZeroExpanded={true}
                  preExpanded={["3"]}
                  onChange={(item) => setOpenAcc4(item)}
                  className="col-span-2"
                >
                  <AccordionItem uuid={"3"} className="accItem mb-3">
                    <AccordionItemHeading>
                      <AccordionItemButton
                        className={`accButton py-4 px-4 flex flex-row justify-between items-center ${
                          openAcc4.includes("3") ? "rounded-t-lg" : "rounded-lg"
                        }`}
                      >
                        <div className="flex flex-row font-semibold text-sm accTitle">
                          <img
                            src={AdditionalImg}
                            alt="additionalImg"
                            className="mr-2"
                          />
                          EBC
                        </div>
                        <AccordionItemState>
                          {({ expanded }) =>
                            expanded ? (
                              <div className="accIconOpen" />
                            ) : (
                              <div className="accIconClose rotate-45" />
                            )
                          }
                        </AccordionItemState>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accPanel grid rounded-b-lg py-4 px-4">
                        {prodData?.productEBC ? (
                          <div
                            className="slickWrapper2"
                            onClick={() => {
                              setIsOpenDocLightBox(true);
                              setDocLightBoxUrl(prodData?.productEBC);
                            }}
                          >
                            <iframe
                              id="iframe2"
                              title={prodData?.productEBC}
                              src={prodData?.productEBC}
                              height="100%"
                              width="100%"
                              style={{ background: "rgba(0, 0, 0, 0.6)" }}
                            ></iframe>
                            <div className="viewBtn">
                              <button>View</button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-col items-center no-img">
                            <div>
                              <img
                                width={100}
                                src={NoProdImgIcon}
                                alt="infoImg"
                              />
                            </div>
                            <p className="font-semibold	text-sm mt-2">
                              No EBC Found
                            </p>
                          </div>
                        )}
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              )}
            </div>
            <div className="flex flex-col mb-3">
              <div
                className="mb-3  rounded-lg h-fit"
                style={{ background: "#FFFFFF", width: 400 }}
              >
                <div className="accButton py-4 px-4 rounded-t-lg">
                  <div className="flex flex-row font-semibold text-sm accTitle">
                    <img src={ProdImgIcon} alt="infoImg" className="mr-2" />
                    Product Images
                  </div>
                </div>
                <div className="p-4">
                  {prodData?.productImage?.length > 0 ? (
                    <>
                      <div className="slickWrapper">
                        <Slick ref={sliderRef} {...setting}>
                          {prodData?.productImage.map((dt, i) => {
                            return (
                              <div
                                className="img relative cursor-pointer"
                                onClick={() => setIsOpenImgLightBox(true)}
                                key={i}
                              >
                                <img src={dt} alt="product" />
                                <div className="viewBtn">
                                  <button>View</button>
                                </div>
                              </div>
                            );
                          })}
                        </Slick>
                      </div>
                      <div className="flex justify-center mt-7">
                        <div
                          onClick={() => sliderRef.current.slickPrev()}
                          className="prevButton cursor-pointer rounded-lg	flex items-center justify-center"
                        >
                          <PrevImg />
                        </div>
                        <div className="slideNum rounded-lg	flex items-center justify-center mx-1.5 py-1.5 px-4">
                          {currentSlide + 1} / {prodData?.productImage?.length}
                        </div>
                        <div
                          onClick={() => sliderRef.current.slickNext()}
                          className="nextButton cursor-pointer rounded-lg	rotate-180 flex items-center justify-center"
                        >
                          <PrevImg />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-col items-center no-img">
                      <div>
                        <img width={100} src={NoProdImgIcon} alt="infoImg" />
                      </div>
                      <p className="font-semibold	text-sm mt-2">
                        No Product Image Found
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {videoArr?.length > 0 && (
                <div
                  className="mb-3 rounded-lg h-fit"
                  style={{ background: "#FFFFFF", width: 400 }}
                >
                  <div className="accButton py-4 px-4 rounded-t-lg">
                    <div className="flex flex-row font-semibold text-sm accTitle">
                      <img src={VideoIcon} alt="infoImg" className="mr-2" />
                      Video
                    </div>
                  </div>
                  <div className="pb-4">
                    {videoArr?.length > 0 ? (
                      <>
                        <div className="">
                          <Slick ref={sliderRef3} {...setting3}>
                            {videoArr.map((dt, i) => {
                              return (
                                <div
                                  className="relative cursor-pointer"
                                  key={i}
                                  onClick={() => {
                                    setOpenModal(true);
                                    const r = dt?.match(rx);
                                    setVideoId(r[1]);
                                  }}
                                >
                                  <YouTube
                                    videoId={dt?.match(rx)[1]}
                                    opts={opts}
                                    className="pointer-events-none	"
                                  />
                                </div>
                              );
                            })}
                          </Slick>
                        </div>
                        <div className="flex justify-center mt-5">
                          <div
                            onClick={() => sliderRef3.current.slickPrev()}
                            className="prevButton cursor-pointer rounded-lg	flex items-center justify-center"
                          >
                            <PrevImg />
                          </div>
                          <div className="slideNum rounded-lg	flex items-center justify-center mx-1.5 py-1.5 px-4">
                            {currentSlide3 + 1} / {videoArr?.length}
                          </div>
                          <div
                            onClick={() => sliderRef3.current.slickNext()}
                            className="nextButton cursor-pointer rounded-lg	rotate-180 flex items-center justify-center"
                          >
                            <PrevImg />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-col items-center no-img">
                        <div>
                          <img width={100} src={NoProdImgIcon} alt="infoImg" />
                        </div>
                        <p className="font-semibold	text-sm mt-2">
                          No Product Video Found
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {prodDetail?.productDocuments?.length > 0 && (
                <div
                  className="mb-3 rounded-lg h-fit"
                  style={{ background: "#FFFFFF", width: 400 }}
                >
                  <div className="accButton py-4 px-4 rounded-t-lg">
                    <div className="flex flex-row font-semibold text-sm accTitle">
                      <img
                        src={AdditionalImg}
                        alt="additionalImg"
                        className="mr-2"
                      />
                      Documents
                    </div>
                  </div>
                  <div className="p-4">
                    {prodDetail?.productDocuments?.length > 0 ? (
                      <>
                        {prodData?.productDocuments.map((dt, i) => (
                          <div
                            className={`flex justify-between ${
                              i === 0 ? "" : "mt-3"
                            }`}
                            key={i}
                          >
                            <p>{dt?.name}</p>
                            <div className="ml-2">
                              <AiOutlineEye
                                color="#e11b22"
                                className="cursor-pointer text-2xl"
                                onClick={() => {
                                  setIsOpenDocLightBox(true);
                                  setDocLightBoxUrl(dt?.url);
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="flex flex-col items-center">
                        <p className="font-semibold	text-sm mt-2">
                          No Product Document Found
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductDetails;
