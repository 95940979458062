import { Subject } from "rxjs";

export const API_BASE_PATH =
  "https://freemansgroup--uat.sandbox.my.salesforce.com/services/apexrest/Portal/";
export const LOGIN_BASE = `https://portal-backend.freemansgroup.com`;
export const PER_PAGE = 50;

export const orderProdDetailLoading = new Subject();

export const orderProdDetailData = new Subject();
