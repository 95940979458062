import React, { useEffect } from "react";

const FormTextarea = (props) => {
  const {
    dom = {},
    label = "",
    error = "",
    errorMessage,
    preFix,
    postFix,
    errorClass = "",
    extraContent,
    wrapperClass = "",
    containerclass = "",
    fullwidth = false,
    required = false,
  } = props;

  const inputRef = React.createRef();
  useEffect(() => {
    dom.autoFocus && inputRef?.current?.focus();
  }, [dom.autoFocus, inputRef]);

  return (
    <div className={`${wrapperClass ? wrapperClass : ""}`}>
      {label && (
        <div className="px-0">
          <label className="mb-2">{label}</label>
          {required ? <span className="asterisk-label ml-1">*</span> : null}
        </div>
      )}
      <div
        className={`flex items-center	relative ${
          containerclass ? containerclass : ""
        }`}
      >
        {preFix && preFix()}
        <textarea
          {...dom}
          ref={inputRef}
          autoComplete="off"
          className={`form-control ${dom.className} ${
            error ? "form-control-error" : ""
          } ${fullwidth ? "w-full" : ""}`}
        />
        {postFix && postFix()}
        {extraContent && extraContent()}
      </div>
      {error ? (
        <div className="input-error-message mt-2.5">
          <span className={` ${errorClass}`}>{errorMessage}</span>
        </div>
      ) : null}
    </div>
  );
};

export default FormTextarea;
