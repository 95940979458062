import React from "react";
import FormInput from "../form/FormInput";
import { GoSearch } from "react-icons/go";
import "./table.scss";

function Search(props) {
  const { search, setSearch, onChange } = props;

  return (
    <FormInput
      dom={{
        type: "text",
        name: "search",
        onChange: (e) => {
          setSearch(e.target.value);
          onChange(e.target.value);
        },
        value: search,
        placeholder: "Search",
        className: "pl-9 leading-5",
      }}
      preFix={() => <GoSearch className="absolute left-2.5" />}
      fullwidth={true}
    />
  );
}

export default Search;
