import React from "react";

function Orders({ color = "black" }) {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 6.72021V5.72021C16 2.96338 13.7568 0.720215 11 0.720215C8.24316 0.720215 6 2.96338 6 5.72021V6.72021H0V26.7202C0 28.9302 1.79004 30.7202 4 30.7202H14.6802C13.8701 30.1499 13.1401 29.48 12.5098 28.7202H4C2.8999 28.7202 2 27.8198 2 26.7202V8.72021H6V10.7202H8V8.72021H14V10.7202H16V8.72021H20V10.77C20.3301 10.7397 20.6602 10.7202 21 10.7202C21.3398 10.7202 21.6699 10.7397 22 10.77V6.72021H16ZM8 6.72021V5.72021C8 4.06592 9.3457 2.72021 11 2.72021C12.6543 2.72021 14 4.06592 14 5.72021V6.72021H8ZM21 30.7202C16.0371 30.7202 12 26.6831 12 21.7202C12 16.7573 16.0371 12.7202 21 12.7202C25.9629 12.7202 30 16.7573 30 21.7202C30 26.6831 25.9629 30.7202 21 30.7202ZM21 14.7202C17.1406 14.7202 14 17.8608 14 21.7202C14 25.5796 17.1406 28.7202 21 28.7202C24.8594 28.7202 28 25.5796 28 21.7202C28 17.8608 24.8594 14.7202 21 14.7202ZM20 25.1343L16.5859 21.7202L18 20.3062L20 22.3062L24 18.3062L25.4141 19.7202L20 25.1343Z"
        fill={color}
      />
    </svg>
  );
}

export default Orders;
